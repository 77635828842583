<template>
  <v-row align="center">
    <v-col cols="12" md="2" class="d-flex align-center">
      <p class="mb-0 font-weight-bold">{{ day_name }}</p>
    </v-col>
    <v-col cols="12" md="3" class="d-flex align-center">
      <v-switch
        color="primary"
        label="Is Open"
        class="d-inline-block pt-0 mt-0"
        v-model="is_open"
        hide-details
        dense
        @change="(_is_open) => (is_open = _is_open)"
      ></v-switch>
    </v-col>
    <v-col cols="12" md="3" v-if="is_open">
      <TimeSelectBox
        label="From"
        dense
        :time="from"
        @update="(_from) => (from = _from)"
        :class="{ 'input-highlight': !from }"
        required
        :rules="[field_req]"
        outlined
      />
    </v-col>
    <v-col cols="12" md="3" v-if="is_open">
      <TimeSelectBox
        label="To"
        :time="to"
        @update="(_to) => (to = _to)"
        :class="{ 'input-highlight': !to }"
        required
        :rules="[field_req]"
        outlined
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { field_req } from "@/utils/form_val_rules";
import TimeSelectBox from "@/views/dashboard/components/shop_settings/TimeSelectBox";

export default {
  props: {
    day_name: { type: String, default: null },
    day: { type: Object, default: null },
  },
  components: {
    TimeSelectBox,
  },
  data() {
    return {
      field_req,
      time_from: null,
      time_to: null,
      takeaway_close: null,
    };
  },
  computed: {
    ...mapState("AdminStore", ["shop"]),
    ...mapGetters("AdminStore", ["has_takeaway_service"]),
    day_copy() {
      return this.day;
    },
    from: {
      get: function () {
        return this.day_copy.from;
      },
      set: function (from) {
        this.$set(this.day_copy, "from", from);
        this.$emit("changed", this.day_copy);
      },
    },
    to: {
      get: function () {
        return this.day_copy.to;
      },
      set: function (to) {
        this.$set(this.day_copy, "to", to);
        this.$emit("changed", this.day_copy);
      },
    },
    is_open: {
      get: function () {
        return this.day_copy !== null && this.day_copy.is_open;
      },
      set: function (is_open) {
        this.$set(this.day_copy, "is_open", is_open);
        this.$emit("changed", this.day_copy);
      },
    },
    takeaway_config() {
      return this.shop.takeaway || {};
    },
  },
};
</script>
