<template>
  <div class="settings-box pa-4 mb-4">
    <v-text-field
      class="required"
      prepend-icon="mdi-currency-gbp"
      label="Delivery Fee (£)"
      v-model="fee"
      type="number"
      step="0.01"
      min="0"
      :rules="rules_cost"
      required
    >
      <template v-slot:append-outer>
        <v-tooltip bottom content-class="custom-tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="accent"
              @click="delete_tier"
              v-bind="attrs"
              v-on="on"
              small
              icon
            >
              <v-icon small color="accent" v-text="'$delete'"
            /></v-btn>
          </template>
          Delete Tier
        </v-tooltip>
      </template>
    </v-text-field>
    <v-autocomplete
      v-if="!acceptEveryPostcode"
      class="required"
      prepend-icon="mdi-map-marker"
      :label="postcode_sectors_label"
      v-model="postcode_sectors"
      :items="availablePostcodes"
      chips
      item-text="name"
      item-value="name"
      multiple
      :rules="rules_postcode"
      hint="Type first 2 alphabets of UK postcode e.g. 'BS' to select"
      persistent-hint
    >
      <template v-slot:selection="data">
        <v-chip
          v-bind="data.attrs"
          :input-value="data.selected"
          close
          @click="data.select"
          @click:close="remove_post_code(data.item)"
        >
          {{ data.item }}
        </v-chip>
      </template>
    </v-autocomplete>
    <PostcodeSelector
      v-else
      :postcodes="postcode_sectors"
      @update="(postcodes) => (postcode_sectors = postcodes)"
    />
  </div>
</template>

<script>
import PostcodeSelector from "@/views/dashboard/components/shop_settings/PostcodeSelector";
import { rules_cost, rules_postcode } from "@/utils/form_val_rules";

import { Decimal } from "decimal.js";

export default {
  name: "DeliveryFeeTierSetting",
  components: {
    PostcodeSelector,
  },
  props: {
    tier: Object,
    acceptEveryPostcode: { type: Boolean, default: false },
    availablePostcodes: { type: Array, default: () => [] },
  },
  data() {
    return {
      rules_cost,
      rules_postcode,
      postcode_sectors_label: "Fee Postcode sectors",
    };
  },
  computed: {
    fee: {
      get() {
        return this.tier.fee;
      },
      set(value) {
        if (value) {
          this.$emit("update:fee", Number(Decimal(value).toFixed(2)));
        }
        this.postcode_sectors_label = value + " Fee Postcode sectors *";
      },
    },
    postcode_sectors: {
      get() {
        return this.tier.postcodes || [];
      },
      set(value) {
        this.$emit("update:postcodes", value);
      },
    },
  },
  methods: {
    remove_post_code(post_code) {
      let pc = this.postcode_sectors;
      pc.splice(
        pc.findIndex((_) => _ === post_code),
        1
      );
    },
    delete_tier() {
      this.$emit("delete");
    },
  },
  created() {
    if (this.fee) {
      this.postcode_sectors_label = this.fee + " Fee Postcode sectors *";
    }
  },
};
</script>
