<template>
  <v-container v-if="shop" fluid tag="section" class="pt-0">
    <v-card elevation="0" class="px-5 py-3">
      <v-tabs
        v-model="active_tab"
        color="primary"
        background-color="white"
        show-arrows
        @change="(v) => (v === 0 ? init_places_autocomplete() : null)"
      >
        <v-tab v-for="tab in settings_tabs" :key="tab.name">
          <v-badge
            color="accent"
            dot
            :value="!(tab.valid === undefined || tab.valid)"
          >
            {{ tab.name }}
          </v-badge>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-if="shop_copy" v-model="active_tab">
        <v-tab-item :key="settings_tabs.general.name">
          <v-container fluid>
            <v-form v-model="settings_tabs.general.valid">
              <v-row v-if="is_directory_shop" class="justify-space-between">
                <v-col cols="12" class="pb-0">
                  <h1 class="text--secondary">Directory Contact Information</h1>
                  <p class="mb-0">
                    Direct your customers to your website and add a customised
                    call-to-action button.
                  </p>
                </v-col>
                <v-col cols="12" md="6" class="mb-0">
                  <v-text-field
                    autocomplete="off"
                    label="Website URL"
                    v-model="shop_copy.directory_settings.website_url"
                    maxlength="200"
                    counter="200"
                    persistent-hint
                    hint="Choose a website URL to display on shopfront header"
                    outlined
                  />
                  <v-text-field
                    autocomplete="off"
                    label="Call-to-action Button"
                    v-model="shop_copy.directory_settings.website_label"
                    maxlength="200"
                    counter="200"
                    hint="Leave blank to use default: 'To buy from us check out our website'"
                    outlined
                  />
                </v-col>
              </v-row>
              <v-row class="justify-space-between">
                <v-col cols="12" class="pb-0">
                  <h1 class="text--secondary">General Information</h1>
                  <p class="mb-0">
                    Please provide all of your business details here.
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6" v-if="!has_completed_induction">
                  <InductionAlert
                    v-model="shop_copy"
                    @induction-passed="remove_induction_flag"
                  />
                </v-col>
                <v-col cols="12" v-else>
                  <v-switch
                    color="primary"
                    label="Go Live"
                    v-model="shop_copy.is_active"
                    persistent-hint
                    hide-details
                    @change="update_shop()"
                    class="d-inline-block"
                  ></v-switch>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-select
                    :items="shop_categories"
                    class="required"
                    :class="{ 'input-highlight': !shop_category }"
                    label="Business Category"
                    placeholder="Select category"
                    v-model="shop_category"
                    required
                    outlined
                  ></v-select>
                  <v-text-field
                    class="required"
                    :class="{ 'input-highlight': !shop_copy.type }"
                    label="Business Type"
                    placeholder="Enter type eg. Bakery, Bookshop"
                    v-model="shop_copy.type"
                    :rules="rules_txt_req"
                    maxlength="50"
                    counter="50"
                    required
                    outlined
                  />
                  <v-text-field
                    id="places-autocomplete"
                    label="Business Address"
                    class="required"
                    :class="{ 'input-highlight': !shop_copy.location }"
                    placeholder="Enter a location"
                    v-model="shop_copy.location"
                    :rules="required_rule"
                    required
                    outlined
                  />
                  <v-textarea
                    label="Welcome Message"
                    class="required"
                    :class="{
                      'input-highlight': !shop_copy.description_header,
                    }"
                    placeholder="Enter welcome message"
                    v-model="shop_copy.description_header"
                    :rules="rules_txt_req"
                    maxlength="400"
                    counter="400"
                    hint="Text will appear on your shopfront header, you can provide tips about how to shop"
                    required
                    outlined
                  />
                  <v-text-field
                    label="Business Short Description"
                    v-model="shop_copy.description_short"
                    maxlength="200"
                    counter="200"
                    hint="Text will appear on the shops main directory, on your shop tile"
                    outlined
                  />
                  <v-textarea
                    label="Business Description"
                    v-model="shop_copy.description"
                    maxlength="900"
                    counter="900"
                    hint="Text will appear on your shopfront, under 'About'"
                    outlined
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <ShopImage
                    id="shop-logo-input"
                    :image-src="shop_copy.logo"
                    :preview-max-width="150"
                    :resize-max-width="300"
                    label="Upload Shop Logo"
                    :action-label="shop_copy.logo ? 'Change Logo' : 'Add Logo'"
                    @update="(img) => (logo_file = img)"
                    @delete="(shop_copy.logo = null), (logo_file = null)"
                  />
                  <ShopImage
                    id="shop-background-input"
                    :image-src="shop_copy.background_image"
                    :preview-max-width="300"
                    :resize-max-width="1920"
                    label="Upload Shop Background"
                    :action-label="
                      shop_copy.background_image ? 'Change Image' : 'Add Image'
                    "
                    @update="(img) => (background_image_file = img)"
                    @delete="
                      (shop_copy.background_image = null),
                        (background_image_file = null)
                    "
                  />
                </v-col>
              </v-row>
            </v-form>
            <SettingsActions
              label="Update general settings"
              :disabled="!all_settings_valid"
              required_field_msg
              @update-shop="
                update_shop({
                  file_path: `shops/${shop_id}/settings/images`,
                })
              "
            />
          </v-container>
        </v-tab-item>
        <v-tab-item :key="settings_tabs.contact.name">
          <SettingsActionTab
            v-if="shop_copy"
            actions_label="Update contact settings"
            @update-shop="update_shop()"
            :disable_actions="!all_settings_valid"
            v-model="settings_tabs.contact.valid"
          >
            <ContactSettings v-model="shop_copy" />
          </SettingsActionTab>
        </v-tab-item>

        <v-tab-item :key="settings_tabs.business_hours.name">
          <v-container fluid v-if="shop_copy">
            <v-row>
              <v-col cols="12" class="pb-0">
                <h1 class="text--secondary">Business Hours</h1>
                <p class="mb-0">
                  Let your customers know what your weekly opening hours are.
                </p>
              </v-col>
              <v-col cols="12" md="6">
                <BusinessDayHours
                  v-for="(day_name, day_no) in days"
                  :key="day_no"
                  :day_name="day_name"
                  :day="shop_copy.business_hours[day_no]"
                  @changed="
                    (day) => handle_business_hours_day_change(day, day_no)
                  "
                />
              </v-col>
            </v-row>

            <SettingsActions
              label="Update business hours"
              @update-shop="update_shop()"
              v-model="settings_tabs.business_hours.valid"
              :disabled="!all_settings_valid"
            />
          </v-container>
        </v-tab-item>

        <v-tab-item :key="settings_tabs.orders.name">
          <SettingsActionTab
            v-if="shop_copy"
            actions_label="Update orders settings"
            @update-shop="update_shop()"
            :disable_actions="!all_settings_valid"
            v-model="settings_tabs.orders.valid"
            required_field_msg
          >
            <OrdersSettings v-model="shop_copy" />
          </SettingsActionTab>
        </v-tab-item>

        <v-tab-item :key="settings_tabs.retail.name">
          <SettingsActionTab
            v-if="shop_copy"
            actions_label="Update retail settings"
            @update-shop="update_shop()"
            :disable_actions="!all_settings_valid"
            v-model="settings_tabs.retail.valid"
            required_field_msg
          >
            <v-expansion-panels hover flat :value.sync="active_retail_panel">
              <CollectionOrdersSettings
                v-model="shop_copy"
                @switch_tab="switch_tab"
                @accept-service="(v) => (v ? (active_retail_panel = 0) : null)"
                :selected_days.sync="selected_days_collection"
              />
              <DeliveryOrdersSettings
                v-model="shop_copy"
                :selected_days.sync="selected_days_delivery"
                @accept-service="(v) => (v ? (active_retail_panel = 1) : null)"
              />
            </v-expansion-panels>
          </SettingsActionTab>
        </v-tab-item>

        <v-tab-item :key="settings_tabs.hospitality.name">
          <SettingsActionTab
            v-if="shop_copy"
            actions_label="Update hospitality settings"
            @update-shop="update_shop()"
            :disable_actions="!all_settings_valid"
            v-model="settings_tabs.hospitality.valid"
            required_field_msg
          >
            <v-row class="justify-space-between mb-6">
              <v-col cols="12" class="pb-0">
                <h1 class="text--secondary">Sound Notification</h1>
                <p class="mb-0">
                  Select a tone which will be played each time you receive new
                  hospitality order.
                </p>
              </v-col>
              <v-col cols="12" md="6">
                <HospitalitySoundNotificationSelect v-model="shop_copy" />
              </v-col>
            </v-row>
            <v-row class="justify-space-between mb-6">
              <v-col cols="12" class="pb-0">
                <h1 class="text--secondary">General Hospitality Information</h1>
                <p class="mb-0">
                  Please provide information about the type of hospitality you
                  provide here. This will help you be more discoverable on the
                  home page.
                </p>
              </v-col>
              <v-col cols="12" md="6">
                <v-autocomplete
                  prepend-icon="mdi-food-fork-drink"
                  label="Select the categories for your hospitality"
                  v-model="shop_cuisines"
                  :items="sort_by_attr(cuisines, 'text')"
                  chips
                  multiple
                  hint="Please note: You can only choose up to 4 main categories for your business."
                  persistent-hint
                  :rules="rules_cuisines"
                  class="mb-3"
                ></v-autocomplete>
                <v-select
                  :items="sort_by_attr(dietary_types, 'text')"
                  prepend-icon="mdi-peanut"
                  label="Select the dietary options for your hospitality"
                  hint="Please note: Only pick the dietary options if you specially cater to those dietary requirements. You can choose up to 3."
                  v-model="shop_dietary"
                  multiple
                  persistent-hint
                  chips
                  :rules="rules_dietary"
                ></v-select>
              </v-col>
            </v-row>
            <v-expansion-panels
              hover
              flat
              :value.sync="active_hospitality_panel"
            >
              <InHouseOrdersSettings
                v-model="shop_copy"
                @accept-service="
                  (v) => (v ? (active_hospitality_panel = 0) : null)
                "
              />
              <TakeawayOrdersSettings
                v-model="shop_copy"
                @switch_tab="switch_tab"
                :selected_days.sync="selected_days_takeaway"
                @accept-service="
                  (v) => (v ? (active_hospitality_panel = 1) : null)
                "
              />
              <HospitalityDeliveryOrdersSettings
                v-model="shop_copy"
                @switch_tab="switch_tab"
                @accept-service="
                  (v) => (v ? (active_hospitality_panel = 2) : null)
                "
                :selected_days.sync="selected_days_hospitality_delivery"
              />
            </v-expansion-panels>
          </SettingsActionTab>
        </v-tab-item>
        <v-tab-item
          :key="settings_tabs.table_booking.name"
          v-if="show_table_booking"
        >
          <v-row class="justify-space-between mb-2">
            <v-col cols="12" class="mb-6">
              <h1 class="text--secondary">Table Booking Subscription</h1>
              <p class="mb-0">
                Our Foodiependent table reservation platform is designed to give
                independent food & drink venues a change to seamlessly take
                customer bookings online affordably.
              </p>
            </v-col>
            <TableBookingSubscription />
          </v-row>
        </v-tab-item>

        <v-tab-item :key="settings_tabs.legal.name">
          <Legals />
        </v-tab-item>

        <v-tab-item :key="settings_tabs.payments.name">
          <StripePaymentsSettings v-if="shop.payment_processor === 'stripe'" />
          <SettingsActionTab
            v-model="settings_tabs.payments.valid"
            v-else-if="shop.payment_processor === 'unify'"
            actions_label="Update Payment Settings"
            @update-shop="update_shop()"
            :disable_actions="!all_settings_valid"
            required_field_msg
          >
            <UnifyPaymentsSettings v-model="shop_copy" />
          </SettingsActionTab>

          <p v-else>
            Unsupported payment processor "{{ shop.payment_processor }}"
          </p>
        </v-tab-item>

        <v-tab-item :key="settings_tabs.notifications.name">
          <NotificationsSettings
            v-model="settings_tabs.notifications.valid"
            :disable_actions="!all_settings_valid"
            :notifications_settings="shop_copy.notifications"
            @notifications-updated="
              (notifications) => {
                shop_copy.notifications = notifications;
                update_shop();
              }
            "
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <v-snackbar
      v-model="snackbar"
      :timeout="3000"
      color="yellow"
      top
      right
      transition="slide-x-reverse-transition"
    >
      <b class="black--text">Changes were saved successfully</b>
      <template v-slot:action>
        <v-icon color="black" @click="snackbar = false"
          >mdi-close-circle-outline</v-icon
        >
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import { Decimal } from "decimal.js";

import { mapState, mapGetters } from "vuex";
import { db } from "@/db";

import { upload_file } from "@/utils/storage.js";
import { sort_by_attr } from "@/utils/utils.js";
import {
  field_req,
  txt_min_2_char,
  valid_email,
  valid_phone,
  url_without_protocol,
  maximum_array_length,
} from "@/utils/form_val_rules";
import { SERVICE_TYPES_INFO } from "@/shared/services";
import { days, DIETARY_TYPES } from "@/shared/const";

import BusinessDayHours from "@/views/dashboard/components/shop_settings/BusinessDayHours";
import Legals from "@/views/dashboard/components/shop_settings/Legals";
import InductionAlert from "@/views/dashboard/components/InductionAlert";
import StripePaymentsSettings from "@/views/dashboard/components/shop_settings/StripePaymentsSettings";
import UnifyPaymentsSettings from "@/views/dashboard/components/shop_settings/UnifyPaymentsSettings";
import NotificationsSettings from "@/views/dashboard/components/shop_settings/NotificationsSettings";
import InHouseOrdersSettings from "@/views/dashboard/components/shop_settings/InHouseOrdersSettings";
import TakeawayOrdersSettings from "@/views/dashboard/components/shop_settings/TakeawayOrdersSettings";
import HospitalityDeliveryOrdersSettings from "@/views/dashboard/components/shop_settings/HospitalityDeliveryOrdersSettings";
import HospitalitySoundNotificationSelect from "@/views/dashboard/components/shop_settings/HospitalitySoundNotificationSelect";
import SettingsActions from "@/views/dashboard/components/shop_settings/SettingsActions";
import OrdersSettings from "@/views/dashboard/components/shop_settings/OrdersSettings";
import SettingsActionTab from "@/views/dashboard/components/shop_settings/SettingsActionTab";
import CollectionOrdersSettings from "@/views/dashboard/components/shop_settings/CollectionOrdersSettings";
import DeliveryOrdersSettings from "@/views/dashboard/components/shop_settings/DeliveryOrdersSettings";
import ContactSettings from "@/views/dashboard/components/shop_settings/ContactSettings";
import ShopImage from "@/views/dashboard/components/shop_settings/ShopImage";
import TableBookingSubscription from "@/views/dashboard/components/shop_settings/TableBookingSubscription";

const SETTINGS_TABS = {
  general: { id: 1, name: "General", valid: true, show: true },
  contact: { id: 2, name: "Contact", valid: true, show: true },
  business_hours: { id: 3, name: "Business Hours", show: true },
  orders: { id: 4, name: "Orders", valid: true, show: true },
  retail: { id: 5, name: "Retail", valid: true, show: true },
  hospitality: { id: 6, name: "Hospitality", valid: true, show: true },
  table_booking: {
    id: 7,
    name: "Table Booking",
    valid: true,
    show: process.env.NODE_ENV !== "production",
  },
  legal: { id: 8, name: "Legal", show: true },
  payments: { id: 9, name: "Payments", valid: true, show: true },
  notifications: { id: 10, name: "Notifications", valid: true, show: true },
};

export default {
  name: "Settings",
  components: {
    BusinessDayHours,
    ShopImage,
    Legals,
    InductionAlert,
    UnifyPaymentsSettings,
    StripePaymentsSettings,
    NotificationsSettings,
    InHouseOrdersSettings,
    TakeawayOrdersSettings,
    HospitalityDeliveryOrdersSettings,
    HospitalitySoundNotificationSelect,
    SettingsActions,
    OrdersSettings,
    SettingsActionTab,
    CollectionOrdersSettings,
    DeliveryOrdersSettings,
    ContactSettings,
    TableBookingSubscription,
  },
  data() {
    return {
      SERVICE_TYPES_INFO,
      days,
      shop_copy: null,

      logo_file: null,
      background_image_file: null,

      selected_days_collection: [],
      selected_days_takeaway: [],
      selected_days_delivery: [],
      selected_days_hospitality_delivery: [],
      snackbar: false,

      active_tab: "General",
      active_retail_panel: null,
      active_hospitality_panel: null,
      settings_tabs: Object.keys(SETTINGS_TABS)
        .filter((key) => SETTINGS_TABS[key].show === true)
        .reduce((current, key) => {
          return Object.assign(current, { [key]: SETTINGS_TABS[key] });
        }, {}),

      required_rule: [field_req],
      rules_txt_req: [field_req, txt_min_2_char],
      rules_email: [valid_email],
      rules_phone: [valid_phone],
      rules_url: [url_without_protocol],
      rules_cuisines: [(v) => maximum_array_length(v, 4)],
      rules_dietary: [(v) => maximum_array_length(v, 3)],
      sort_by_attr,
      // cut_off_dialog_shown: false,
    };
  },
  computed: {
    ...mapState("AdminStore", [
      "shop",
      "shop_id",
      "categories",
      "hospitality_cuisines",
    ]),
    ...mapGetters("AdminStore", ["is_directory_shop"]),
    shop_categories: function () {
      return this.categories.map((c) => c.name);
    },
    cuisines() {
      return Object.keys(this.hospitality_cuisines).map((key) => {
        return { text: this.hospitality_cuisines[key], value: key };
      });
    },
    dietary_types() {
      return Object.keys(DIETARY_TYPES).map((key) => {
        return { text: DIETARY_TYPES[key], value: key };
      });
    },
    shop_cuisines: {
      get() {
        if (!this.shop_copy?.cuisines) return [];
        return Object.keys(this.shop_copy.cuisines);
      },
      set(value) {
        this.shop_copy.cuisines = value.reduce((obj, cuisine) => {
          return {
            ...obj,
            [cuisine]: this.hospitality_cuisines[cuisine],
          };
        }, {});
      },
    },
    shop_dietary: {
      get() {
        if (!this.shop_copy?.dietary) return [];
        return Object.keys(this.shop_copy.dietary);
      },
      set(value) {
        this.shop_copy.dietary = value.reduce((obj, option) => {
          return {
            ...obj,
            [option]: DIETARY_TYPES[option],
          };
        }, {});
      },
    },
    shop_category: {
      get: function () {
        if (!this.shop_copy) return;
        if (isEmpty(this.shop_copy.categories)) return null;

        const category = Object.entries(this.shop_copy.categories).filter(
          (entry) => entry[1] === true // [k,v] > c.id, is_active
        )[0];
        const category_id = category[0];
        return this.categories.find((c) => c.id === category_id).name;
      },
      set: function (category) {
        if (!this.shop_copy) return;

        const cat = this.categories.find((c) => c.name === category);
        this.shop_copy["categories"] = { [cat.id]: true };
      },
    },
    has_completed_induction() {
      return this.shop && !this.shop.flags.includes("induction");
    },
    all_settings_valid() {
      return Object.values(this.settings_tabs)
        .map((tab) => [undefined, true].includes(tab.valid))
        .every(Boolean);
    },
    show_table_booking() {
      return process.env.NODE_ENV !== "production";
    },
  },
  mounted() {
    SETTINGS_TABS.contact.valid =
      this.shop_copy.email && this.shop_copy.mobile_no ? true : false;

    SETTINGS_TABS.business_hours.valid =
      Object.values(this.shop_copy.business_hours).some(
        ({ is_open }) => is_open
      ) || false;
  },
  watch: {
    shop: {
      immediate: true,
      handler(shop) {
        this.shop_copy = cloneDeep(shop);

        this.set_orders_tab_collection_data();
        this.set_orders_tab_delivery_data();
        this.set_orders_tab_takeaway_data();
        this.set_orders_tab_hospitality_delivery_data();
      },
    },
  },
  methods: {
    async init_places_autocomplete() {
      // cheeky delay to allow input render in tab content
      const delay = (ms) => new Promise((res) => setTimeout(res, ms));
      await delay(100);

      const input = document.getElementById("places-autocomplete");
      const google = await this.$gmapApiPromiseLazy();
      const autocomplete = new google.maps.places.Autocomplete(input);

      let requested_fields = this.api_requested_fields || [
        "geometry",
        "formatted_address",
      ];
      autocomplete.setFields(requested_fields);
      autocomplete.addListener("place_changed", async () => {
        const place = autocomplete.getPlace();
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();

        this.shop_copy.geolocation = {
          lat: lat,
          lng: lng,
          geopoint: new firebase.firestore.GeoPoint(lat, lng),
        };
        this.shop_copy.location = input.value;

        document.getElementById("places-autocomplete").blur();
      });
    },
    async update_shop({ silent = false, file_path = "" } = {}) {
      if (file_path) {
        const logo = this.logo_file;
        const bg_file = this.background_image_file;
        try {
          if (logo) {
            const logo_url = await upload_file(file_path, logo);
            this.shop_copy["logo"] = logo_url;
          }
          if (bg_file) {
            const bg_url = await upload_file(file_path, bg_file);
            this.shop_copy["background_image"] = bg_url;
          }
        } catch (error) {
          console.log("File upload failed! ", error);
        }
      }

      await this.update();

      if (!silent) {
        this.snackbar = true;
      }
    },
    async update() {
      this.format_shop();

      return db
        .collection("shops")
        .doc(this.shop_id)
        .update({
          ...this.shop_copy,
          updated_at: firebase.firestore.FieldValue.serverTimestamp(),
        });
    },
    handle_business_hours_day_change(day, day_no) {
      this.$set(this.shop_copy.business_hours, day_no, day);
      this.validate_business_hours();
    },
    validate_business_hours() {
      const shop_copy = this.shop_copy;
      let valid = false;
      let valid_days = Object.values(shop_copy.business_hours).filter(
        ({ is_open }) => is_open
      );

      if (valid_days.length) {
        valid = valid_days
          .map(
            ({ is_open, from, to }) => is_open && from !== null && to !== null
          )
          .every((v) => Boolean(v));
      }

      this.$set(this.settings_tabs, "business_hours", {
        ...this.settings_tabs.business_hours,
        valid,
      });
      this.$forceUpdate();
    },
    format_shop() {
      if (!this.shop_copy) return;

      // Format or clear cutoff time related data
      // const cutoff_time = this.shop_copy.acquire_items.cutoff_time;
      // this.shop_copy.acquire_items.cutoff_time_no = cutoff_time
      //   ? get_HHmm_time_as_seconds(cutoff_time)
      //   : null;

      // Format or clear service data
      const service = this.shop_copy.acquire_items.service;
      // COLLECTION
      if (service.collection) {
        let selected_day_nos = null;
        if (this.selected_days_collection.length > 0) {
          selected_day_nos = this.set_selected_day_nos(
            this.selected_days_collection
          );
        }
        this.format_service(this.shop_copy.collection, selected_day_nos);
      }

      // TAKEAWAY
      if (service.takeaway) {
        let selected_day_nos = null;
        if (this.selected_days_takeaway.length > 0) {
          selected_day_nos = this.set_selected_day_nos(
            this.selected_days_takeaway
          );
        }
        this.format_service(this.shop_copy.takeaway, selected_day_nos);
      }

      // HOSPITALITY DELIVERY
      if (service.hospitality_delivery) {
        let selected_day_nos = null;
        if (this.selected_days_hospitality_delivery.length > 0) {
          selected_day_nos = this.set_selected_day_nos(
            this.selected_days_hospitality_delivery
          );
        }
        this.format_service(
          this.shop_copy.hospitality_delivery,
          selected_day_nos
        );
      }

      // DELIVERY
      if (service.delivery) {
        let selected_day_nos = null;
        if (this.selected_days_delivery.length > 0) {
          selected_day_nos = this.set_selected_day_nos(
            this.selected_days_delivery
          );
        }
        this.format_service(this.shop_copy.delivery, selected_day_nos);
      }

      if (this.shop_copy.custom_fee?.fee) {
        this.shop_copy.custom_fee.fee = Number(
          Decimal(this.shop_copy.custom_fee.fee).toFixed(2)
        );
      }
    },
    format_service(service, service_days) {
      Object.keys(service).forEach((att) => {
        switch (att) {
          case "fee":
          case "minimum":
            if (service[att]) {
              service[att] = Number(Decimal(service[att]).toFixed(2));
            }
            break;
          case "free_after":
            if (service[att]) {
              service[att] = Number(Decimal(service[att]).toFixed(2));
            }
            break;
          case "fulfilment_max_days":
            if (service[att] !== null) {
              service[att] = Number(Decimal(service[att]).toFixed(0));
              service.allow_future_scheduled_orders = service[att] !== 0;
            } else {
              service[att] = null;
            }
            break;
          case "fulfilment_slots":
            if (service_days) {
              Object.keys(service[att]).map((slot) => {
                return service_days.some((s_day) => s_day === slot)
                  ? (service[att][slot].is_available = true)
                  : (service[att][slot].is_available = false);
              });
            } else {
              service[att] = this.reset_fulfilment_slots();
            }
            break;
          default:
            break;
        }
      });
    },
    set_orders_tab_takeaway_data() {
      if (this.shop?.takeaway?.fulfilment_slots) {
        // Initialise takeaway days dropdown, else migrate
        this.selected_days_takeaway = this.populate_selected_days(
          this.shop.takeaway.fulfilment_slots
        );
      } else {
        this.shop_copy.takeaway.fulfilment_slots =
          this.reset_fulfilment_slots("T");
        this.update_shop({ silent: true });
      }
    },
    set_orders_tab_collection_data() {
      if (this.shop?.collection?.fulfilment_slots) {
        // Initialise collection days dropdown, else migrate
        this.selected_days_collection = this.populate_selected_days(
          this.shop.collection.fulfilment_slots
        );
      } else {
        this.shop_copy.collection.fulfilment_slots =
          this.reset_fulfilment_slots("C");
        this.update_shop({ silent: true });
      }
    },
    set_orders_tab_delivery_data() {
      if (this.shop?.delivery?.fulfilment_slots) {
        // Initialise delivery days dropdown, else migrate
        this.selected_days_delivery = this.populate_selected_days(
          this.shop.delivery.fulfilment_slots
        );
      } else {
        this.shop_copy.delivery.fulfilment_slots =
          this.reset_fulfilment_slots("D");
        this.update_shop({ silent: true });
      }
    },
    set_orders_tab_hospitality_delivery_data() {
      if (this.shop?.hospitality_delivery?.fulfilment_slots) {
        // Initialise delivery days dropdown, else migrate
        this.selected_days_hospitality_delivery = this.populate_selected_days(
          this.shop.hospitality_delivery.fulfilment_slots
        );
      } else {
        this.shop_copy.hospitality_delivery.fulfilment_slots =
          this.reset_fulfilment_slots("HD");
        this.update_shop({ silent: true });
      }
    },
    populate_selected_days(fulfilment_slots) {
      const selected_day_nos = Object.keys(fulfilment_slots).filter((slot) => {
        return fulfilment_slots[slot].is_available === true;
      });

      // Based on this.days object (in data)
      // Given an array of days-keys, returns an array of days-values
      const days = this.days;
      let selected_days = [];
      selected_day_nos.forEach((day_no) => {
        selected_days.push(days[day_no]);
      });
      return selected_days;
    },
    set_selected_day_nos(selected_days) {
      // Based on this.days object (in data)
      // Given an array of days-values, returns an array of days-keys
      const days = this.days;

      let selected_day_nos = [];
      selected_days.forEach((day) => {
        let day_no = Object.keys(days).find((k) => days[k] === day);
        selected_day_nos.push(day_no);
      });
      return selected_day_nos;
    },
    reset_fulfilment_slots(service = "") {
      const day_numbers = Object.keys(this.days);
      const fulfilment_slots = {};

      day_numbers.forEach((day_no) => {
        fulfilment_slots[day_no] = {
          is_available: false,
        };
      });

      if (service && service === "C") {
        this.selected_days_collection = [];
      }

      if (service && service === "T") {
        this.selected_days_takeaway = [];
      }

      if (service && service === "D") {
        this.selected_days_delivery = [];
      }

      if (service && service === "HD") {
        this.selected_days_hospitality_delivery = [];
      }
      return fulfilment_slots;
    },
    switch_tab(tab) {
      this.active_tab = tab;
    },
    remove_induction_flag() {
      this.shop_copy.flags.splice(this.shop_copy.flags.indexOf("induction"), 1);
      this.update_shop({ silent: true });
    },
  },
};
</script>

<style lang="scss">
.flex {
  display: flex;
  justify-content: space-between;
}

.v-select.v-select--chips .v-select__selections {
  min-height: 0 !important;
}

.v-text-field.v-text-field--enclosed .v-text-field__details {
  padding: 0 !important;
}
</style>
