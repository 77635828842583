<template>
  <div class="settings-tab-actions d-flex py-4 pr-4 justify-end">
    <v-tooltip bottom :disabled="!disabled">
      <template v-slot:activator="{ on }">
        <div v-on="on" class="d-inline-block">
          <v-btn
            color="primary"
            rounded
            class="ma-0"
            @click="$emit('update-shop')"
            :disabled="disabled"
          >
            {{ label }}
          </v-btn>
        </div>
      </template>
      <span>One or more of the settings is invalid</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: "SettingsActions",
  props: {
    label: {
      type: String,
      default: "Update Settings",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required_field_msg: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
.settings-tab-actions {
  border-top: 1px solid #eee;
  position: sticky;
  bottom: 0;
  background-color: white;
  z-index: 1;
}
</style>
