<template>
  <v-container fluid>
    <v-row class="mb-2">
      <v-col>
        <span class="text--secondary">
          You already have the base T&Cs your shop needs but it is good practice
          to complete these sections. They are all visible to end users when
          they click Terms and Conditions on your shop.
        </span>
      </v-col>
    </v-row>
    <v-form>
      <v-expansion-panels v-model="panel_index">
        <v-expansion-panel v-for="(doc, index) in legal_docs" :key="index">
          <v-expansion-panel-header class="justify-space-between">
            <h3>{{ doc.label }}</h3>
            <v-btn
              text
              right
              class="ml-auto flex-grow-0"
              color="primary"
              @click.stop="open_legal(doc.id, doc.label, index)"
              >Edit</v-btn
            >
          </v-expansion-panel-header>
          <v-expansion-panel-content class="legal-content-preview scroll-y">
            <template v-if="doc.content">
              <p v-html="doc.content"></p>
            </template>
            <template v-else>
              <p>-</p>
            </template>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-form>

    <v-dialog
      v-model="dialog_legal"
      max-width="1200"
      :fullscreen="$vuetify.breakpoint.mdAndDown"
    >
      <v-card>
        <v-card-title class="headline">{{
          current_legal_doc.label
        }}</v-card-title>

        <v-card-text>
          <editor-menu-bar
            class=""
            :editor="editor"
            v-slot="{ commands, isActive }"
          >
            <div class="tiptap-menu">
              <button
                class="menubar__button"
                :class="{
                  'is-active': isActive.heading({ level: 1 }),
                }"
                @click="commands.heading({ level: 1 })"
              >
                <v-icon>mdi-format-header-1</v-icon>
              </button>

              <button
                class="menubar__button"
                :class="{
                  'is-active': isActive.heading({ level: 2 }),
                }"
                @click="commands.heading({ level: 2 })"
              >
                <v-icon>mdi-format-header-2</v-icon>
              </button>

              <button
                class="menubar__button"
                :class="{
                  'is-active': isActive.heading({ level: 3 }),
                }"
                @click="commands.heading({ level: 3 })"
              >
                <v-icon>mdi-format-header-3</v-icon>
              </button>

              <button
                class="menubar__button"
                :class="{ 'is-active': isActive.paragraph() }"
                @click="commands.paragraph"
              >
                <v-icon>mdi-format-paragraph</v-icon>
              </button>

              <button
                class="menubar__button"
                :class="{ 'is-active': isActive.bold() }"
                @click="commands.bold"
              >
                <v-icon>mdi-format-bold</v-icon>
              </button>

              <button
                class="menubar__button"
                :class="{ 'is-active': isActive.italic() }"
                @click="commands.italic"
              >
                <v-icon>mdi-format-italic</v-icon>
              </button>

              <button
                class="menubar__button"
                :class="{ 'is-active': isActive.underline() }"
                @click="commands.underline"
              >
                <v-icon>mdi-format-underline</v-icon>
              </button>

              <button
                class="menubar__button"
                :class="{ 'is-active': isActive.bullet_list() }"
                @click="commands.bullet_list"
              >
                <v-icon>mdi-format-list-bulleted</v-icon>
              </button>

              <button
                class="menubar__button"
                :class="{ 'is-active': isActive.ordered_list() }"
                @click="commands.ordered_list"
              >
                <v-icon>mdi-format-list-numbered</v-icon>
              </button>

              <button class="menubar__button" @click="commands.undo">
                <v-icon>undo</v-icon>
              </button>

              <button class="menubar__button" @click="commands.redo">
                <v-icon>redo</v-icon>
              </button>
            </div>
          </editor-menu-bar>
          <editor-content class="tiptap" :editor="editor" />
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="close_legal()"> close </v-btn>
          <v-btn color="primary darken-1" @click="add_legal()"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { db } from "@/db";
import { Editor, EditorContent, EditorMenuBar } from "tiptap";
import {
  HardBreak,
  Heading,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Italic,
  Underline,
  History,
} from "tiptap-extensions";

export default {
  name: "Legals",
  components: { EditorContent, EditorMenuBar },
  data() {
    return {
      dialog_legal: false,
      editor: new Editor({
        extensions: [
          new BulletList(),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3] }),
          new ListItem(),
          new OrderedList(),
          new TodoItem(),
          new TodoList(),
          new Bold(),
          new Italic(),
          new Underline(),
          new History(),
        ],
        content: "",
      }),
      legal_docs: [
        {
          id: "company-information",
          label: "Company Information",
          content: "",
        },
        {
          id: "privacy-policy",
          label: "Privacy Policy",
          content: "",
        },
        {
          id: "returns-and-refunds",
          label: "Returns and Refunds",
          content: "",
        },
        {
          id: "description-of-goods",
          label: "Description of Goods and Services offered",
          content: "",
        },
        {
          id: "transaction-currency",
          label: "Transaction Currency",
          content: "",
        },
        {
          id: "customer-service",
          label: "Customer service contact details (phone, email, address)",
          content: "",
        },
        {
          id: "warranty",
          label: "Warranty",
          content: "",
        },
      ],
      current_legal_doc: {
        id: null,
        label: null,
      },
      panel_index: null,
    };
  },
  computed: {
    ...mapState("AdminStore", ["shop_id"]),
  },
  methods: {
    async open_legal(legal_doc_id, label, index) {
      if (!this.legal_docs[index].content) {
        this.legal_docs[index].content = await this.display_legal(legal_doc_id);
      }

      this.editor.setContent(this.legal_docs[index].content);

      this.current_legal_doc.id = legal_doc_id;
      this.current_legal_doc.label = label;
      this.dialog_legal = true;
    },
    async add_legal() {
      await db
        .collection("shops")
        .doc(this.shop_id)
        .collection("legals")
        .doc(this.current_legal_doc.id)
        .set({
          content:
            this.editor.getHTML() === "<p></p>" ? "" : this.editor.getHTML(),
          label: this.current_legal_doc.label,
        });

      let idx = this.legal_docs.findIndex(
        (e) => e.id === this.current_legal_doc.id
      );
      this.legal_docs[idx].content = this.editor.getHTML();
      this.close_legal();
    },
    close_legal() {
      this.current_legal_doc.id = null;
      this.current_legal_doc.label = null;
      this.dialog_legal = false;
      this.editor.clearContent();
    },
    async display_legal(legal_doc_id) {
      return db
        .collection("shops")
        .doc(this.shop_id)
        .collection("legals")
        .doc(legal_doc_id)
        .get()
        .then((legal) => {
          if (legal && legal.data()) {
            return legal.data().content;
          } else {
            return "";
          }
        });
    },
  },
  watch: {
    async panel_index(new_index, old_index) {
      if (
        new_index !== undefined &&
        old_index !== new_index &&
        !this.legal_docs[new_index].content
      ) {
        this.legal_docs[new_index].content = await this.display_legal(
          this.legal_docs[new_index].id
        );
      }
    },
  },
  beforeDestroy() {
    this.editor.destroy();
  },
};
</script>

<style lang="scss">
.tiptap {
  .ProseMirror {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    padding: 1rem;
    min-height: 200px;
    max-height: 600px;
    overflow-y: auto;

    &:focus {
      outline: none;
    }
  }
}
.tiptap-menu {
  .menubar__button {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0 0.4rem;
    margin: 0.2rem 0.1rem;

    &.is-active {
      background: rgba(0, 0, 0, 0.2);
      border-radius: 3px;
    }
    &:first-child {
      border-left: 1px solid rgba(0, 0, 0, 0.1);
    }

    &:focus {
      outline: none;
    }
  }
}
.legal-content-preview {
  max-height: 500px;
  white-space: pre-wrap;
}
</style>
