<template>
  <v-row class="justify-space-between">
    <v-col cols="12" class="pb-0">
      <h1 class="text--secondary">Contact Information</h1>
      <p class="mb-0">Please provide all of your contact details here.</p>
    </v-col>
    <v-col cols="12" md="6">
      <v-text-field
        label="Business Email"
        prepend-inner-icon="mdi-at"
        required
        v-model="value.email"
        :rules="rules_email"
        class="required"
        :class="{
          'input-highlight': !value.email,
        }"
        outlined
      />
      <v-text-field
        label="Main Phone Number"
        prepend-inner-icon="mdi-cellphone"
        v-model="value.mobile_no"
        :rules="[field_req, valid_phone]"
        class="required"
        :class="{
          'input-highlight': !value.mobile_no,
        }"
        outlined
        required
      />
      <v-text-field
        label="Secondary Phone Number"
        prepend-inner-icon="mdi-phone-classic"
        v-model="value.phone_no"
        :rules="[valid_phone]"
        outlined
      />
      <v-text-field
        label="Business Website"
        :placeholder="`Eg. www.${lowercase_brand_name}.co.uk`"
        prepend-inner-icon="mdi-web"
        v-model="value.website"
        :rules="rules_url"
        maxlength="150"
        counter="150"
        outlined
      />
      <v-text-field
        label="Facebook Link"
        :placeholder="`Eg. facebook.com/${lowercase_brand_name}`"
        prepend-inner-icon="mdi-facebook"
        v-model="value.social_media.facebook"
        :rules="rules_url"
        maxlength="150"
        counter="150"
        outlined
      />
      <v-text-field
        label="Instagram Link"
        :placeholder="`Eg. instagram.com/${lowercase_brand_name}`"
        prepend-inner-icon="mdi-instagram"
        v-model="value.social_media.instagram"
        :rules="rules_url"
        maxlength="150"
        counter="150"
        outlined
      />
      <v-text-field
        label="Twitter Link"
        :placeholder="`Eg. twitter.com/${lowercase_brand_name}`"
        prepend-inner-icon="mdi-twitter"
        v-model="value.social_media.twitter"
        :rules="rules_url"
        maxlength="150"
        counter="150"
        outlined
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

import { VModelObjectMixin } from "@/components/mixins/InputMixins";
import {
  valid_email,
  valid_phone,
  url_without_protocol,
  field_req,
} from "@/utils/form_val_rules";

export default {
  name: "ContactSettings",
  mixins: [VModelObjectMixin],
  data() {
    return {
      valid_phone,
      field_req,
      rules_email: [field_req, valid_email],
      rules_url: [url_without_protocol],
    };
  },
  computed: {
    ...mapGetters("BrandStore", ["lowercase_brand_name"]),
  },
};
</script>
