<template>
  <div class="mr-4 d-inline-flex flex-column align-baseline">
    <div class="d-flex align-center">
      <p
        class="
          mb-0
          mr-2
          headline
          font-weight-bold
          dark-grey--text
          d-inline-block
        "
      >
        {{ label }}
      </p>
      <label class="primary--text cursor-pointer" :for="id">{{
        actionLabel
      }}</label>
    </div>
    <image-uploader
      :id="id"
      :debug="0"
      :className="['hidden tiny']"
      :maxWidth="resizeMaxWidth"
      :quality="0.7"
      :autoRotate="true"
      outputFormat="blob"
      :preview="false"
      :capture="false"
      accept="image/*"
      doNotResize="['gif', 'svg']"
      @input="(file) => (selected_file = file)"
      @onUpload="loading_image = true"
      @onComplete="loading_image = false"
    >
    </image-uploader>
    <div v-if="image" id="image-output" class="shop-image">
      <v-progress-circular
        v-if="loading_image"
        :size="20"
        indeterminate
        color="primary"
      />
      <v-img class="br-16" :src="image" :max-width="previewMaxWidth" />
      <v-btn
        small
        icon
        class="--button"
        @click="(selected_file = null), (image = null), $emit('delete')"
        ><v-icon small color="accent" v-text="'$delete'"
      /></v-btn>
    </div>
  </div>
</template>

<script>
import ImageUploader from "vue-image-upload-resize";
export default {
  name: "ShopImage",
  props: {
    id: { type: String, default: null },
    imageSrc: { type: String, default: null },
    label: { type: String, default: "Add image" },
    actionLabel: { type: String, default: "Add image" },
    previewMaxWidth: { type: Number, default: 100 },
    resizeMaxWidth: { type: Number, default: 100 },
  },
  components: {
    ImageUploader,
  },
  data() {
    return {
      selected_file: null,
      image: null,
      loading_image: false,
    };
  },
  created() {
    this.parse_image(this.imageSrc);
  },
  watch: {
    imageSrc: function (src) {
      this.parse_image(src);
    },
    selected_file: function (file) {
      this.$emit("update", file);
      this.parse_image(file);
    },
  },
  methods: {
    parse_image(img_blob_or_url) {
      let url = img_blob_or_url;
      if (img_blob_or_url instanceof Blob) {
        url = window.URL.createObjectURL(img_blob_or_url);
      }
      this.image = url;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .tiny {
  height: 1px;
  width: 1px;
  line-height: 0;
}
.shop-image {
  position: relative;
  max-width: unset;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  .--button {
    opacity: 1;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      opacity: 0;
    }

    background-color: var(--v-light-grey-base);
    position: absolute;
    top: -10px;
    right: -14px;
  }

  &:hover {
    .--button {
      opacity: 1;
    }
  }
}
</style>
