<template>
  <div>
    <p class="mb-0 font-weight-bold">{{ days[day_no] }}</p>
    <v-row align="center" col="12">
      <v-col cols="12" md="4">
        <v-select
          class="required"
          prepend-icon="mdi-clock-outline"
          :items="from_time_range"
          v-model="_time_from"
          label="From"
          :rules="[field_req]"
          required
        ></v-select>
      </v-col>
      <v-col cols="12" md="4">
        <v-select
          class="required"
          prepend-icon="mdi-clock-outline"
          :disabled="!_time_from"
          :items="to_time_range"
          v-model="_time_to"
          label="To"
          :rules="[field_req]"
          required
        ></v-select>
      </v-col>
      <v-col cols="12" md="4">
        <v-select
          prepend-icon="mdi-clock-outline"
          v-model="_time_cutoff"
          :disabled="!_time_to"
          :items="cutoff_time_range"
          label="Cutoff Time"
        ></v-select>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  get_HHmm_time_as_seconds,
  get_seconds_as_HHmm,
} from "@/utils/date_utils";
import range from "lodash/range";
import { days } from "@/shared/const";
import { field_req } from "@/utils/form_val_rules";

export default {
  name: "FulfilmentTimeSlot",
  props: {
    time_to: {
      type: String,
    },
    time_from: {
      type: String,
    },
    time_cutoff: {
      type: String,
    },
    day_no: {
      type: Number,
    },
    business_hours: {
      type: Object,
    },
  },
  data() {
    return {
      days,
      field_req,
      menu_from: false,
      menu_to: false,
      menu_cutoff: false,
    };
  },
  computed: {
    from_time_range() {
      const from = "00:00";
      const to = "24:00";

      const seconds_in_15_mins = 900;
      const available_times_in_seconds = range(
        get_HHmm_time_as_seconds(from),
        get_HHmm_time_as_seconds(to) + 900,
        seconds_in_15_mins
      );
      return available_times_in_seconds.map(get_seconds_as_HHmm);
    },
    to_time_range() {
      const from = this._time_from;
      if (!from) return [];
      const to = "24:00";

      const seconds_in_15_mins = 900;
      const available_times_in_seconds = range(
        get_HHmm_time_as_seconds(from),
        get_HHmm_time_as_seconds(to) + 900,
        seconds_in_15_mins
      );
      return available_times_in_seconds.map(get_seconds_as_HHmm);
    },
    cutoff_time_range() {
      const from = this._time_from;
      const to = this._time_to;

      if (!from || !to) return [];

      const seconds_in_15_mins = 900;
      const available_times_in_seconds = range(
        get_HHmm_time_as_seconds(from),
        get_HHmm_time_as_seconds(to) + 900,
        seconds_in_15_mins
      );
      return available_times_in_seconds.map(get_seconds_as_HHmm);
    },
    _time_from: {
      get() {
        return this.time_from;
      },
      set(value) {
        this.$emit("update:from", value);
      },
    },
    _time_to: {
      get() {
        return this.time_to;
      },
      set(value) {
        this.$emit("update:to", value);
      },
    },
    _time_cutoff: {
      get() {
        return this.time_cutoff;
      },
      set(value) {
        this.$emit("update:cutoff", value);
      },
    },
  },
};
</script>
