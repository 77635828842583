<template>
  <v-container fluid v-if="notifications_settings">
    <v-form :value="value" @input="(value) => $emit('input', value)">
      <v-row class="justify-space-between">
        <v-col cols="12" class="pb-0">
          <h1 class="text--secondary">New Order Notification</h1>
          <p class="mb-0">
            Receive email notification every time someone makes a purchase in
            your shop.
          </p>
        </v-col>
        <v-col cols="12" md="6">
          <v-checkbox
            color="primary"
            label="Enable Notification"
            hide-details="auto"
            class="d-inline-block mb-6"
            v-model="new_order_notification_enabled"
          />
          <v-combobox
            v-if="new_order_notification_enabled"
            v-model="new_order_notification_recipients"
            :items="shop_emails"
            :delimiters="combobox_delimiters"
            :rules="notification_receivers_rules"
            :chips="true"
            clearable
            deletable-chips
            hide-selected
            label="Recipients of New Order Email *"
            hint="Selected addresses will get an email every time a customer places an order.
                   Select from existing addresses or type new one and confirm by pressing [space]."
            :persistent-hint="true"
            multiple
          ></v-combobox>
        </v-col>
      </v-row>
    </v-form>
    <SettingsActions
      label="Update notifications settings"
      required_field_msg
      :disabled="disable_actions"
      @update-shop="$emit('notifications-updated', settings)"
    />
  </v-container>
</template>

<script>
import { db } from "@/db";
import { mapState } from "vuex";

import SettingsActions from "@/views/dashboard/components/shop_settings/SettingsActions";
import { is_not_empty_array, all_emails_valid } from "@/utils/form_val_rules";

export default {
  name: "NotificationsSettings",
  components: {
    SettingsActions,
  },
  data() {
    return {
      admin_email: null,
      combobox_delimiters: [" "],
      shop_emails: [],
      notifications_valid: false,
      notification_receivers_rules: [is_not_empty_array, all_emails_valid],

      new_order_notification_recipients:
        this.notifications_settings?.new_order?.receivers || [],
      new_order_notification_enabled:
        this.notifications_settings?.new_order?.is_active || false,
    };
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    notifications_settings: {
      type: Object,
      default: () => {},
    },
    disable_actions: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapState("AdminStore", ["shop_id", "shop"]),
    settings: function () {
      return {
        new_order: {
          is_active: this.new_order_notification_enabled,
          receivers: this.new_order_notification_recipients,
        },
      };
    },
  },
  async created() {
    this.admin_email = await db
      .collection("shops")
      .doc(this.shop_id)
      .collection("admin_data")
      .doc("authentication")
      .get()
      .then((doc) => (doc.exists ? doc.data().email : null));

    this.shop_emails = this.shop.email
      ? [this.shop.email, this.admin_email]
      : [this.admin_email];
  },
};
</script>
