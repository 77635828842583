var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"scrollable":"","fullscreen":_vm.$vuetify.breakpoint.smAndDown,"persistent":"","width":"60vw"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"required",attrs:{"label":"Postcodes","autocomplete":"new-password","value":("Selected " + (_vm.selected_postcodes.length) + " postcodes"),"rules":[
        function () { return _vm.selected_postcodes.length ? true : 'Select at least one postcode'; } ],"required":""},on:{"click":function($event){_vm.dialog = true}}},on))]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.dialog)?_c('v-card',{staticClass:"action-modal br-16"},[_c('v-card-text',[_c('v-text-field',{staticClass:"mb-4",attrs:{"label":"Search Postcode","hint":"Type first 3 characters of UK postcode e.g. 'BS3' to filter","persistent-hint":"","autocomplete":"new-password"},on:{"input":_vm.handle_search}}),_c('v-container',{staticClass:"action-modal-content",staticStyle:{"max-height":"60vh"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('p',[_vm._v("Selected "+_vm._s(_vm.selected_postcodes.length)+" postcodes")]),_vm._l((_vm.selected_postcodes),function(pc){return _c('v-chip',{key:("pc_" + pc),staticClass:"mr-2 mb-2",class:{
                'purple lighten-3': pc
                  .toLowerCase()
                  .includes(_vm.postcode_search),
              }},[_vm._v(_vm._s(pc))])})],2),_c('v-col',{attrs:{"cols":"12","md":"9"}},[_c('v-treeview',{ref:"tree",attrs:{"open":_vm.open,"search":_vm.postcode_search,"items":_vm.items,"selectable":"","selection-type":"leaf","item-key":"name"},on:{"update:open":function($event){_vm.open=$event}},model:{value:(_vm.selected_postcodes),callback:function ($$v) {_vm.selected_postcodes=$$v},expression:"selected_postcodes"}})],1)],1)],1)],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"color":"black","rounded":"","text":""},on:{"click":_vm.cancel}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"primary","rounded":"","elevation":"0"},on:{"click":_vm.close}},[_vm._v("Save")])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }