<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      <div class="d-block">
        <h3
          class="
            display-2
            d-flex
            align-center
            text--secondary
            mb-4
            text-center text-md-left
          "
        >
          Takeaway
        </h3>
        <v-row class="justify-space-between">
          <v-col cols="12" md="6">
            <v-checkbox
              color="primary"
              v-model="value.acquire_items.service.takeaway"
              hide-details="auto"
              class="ma-0 d-inline-block"
              v-on:click.stop.prevent
              @change="(v) => $emit('accept-service', v)"
            >
              <template v-slot:label>
                <p class="text-h4 font-weight-bold ma-0 pa-0">
                  Accept Orders for Takeaway
                </p>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
      </div>
      <template v-slot:actions>
        <v-icon large> mdi-chevron-down</v-icon>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <div v-if="value.acquire_items.service.takeaway" class="pt-4">
        <v-row class="justify-space-between settings-box pa-4 mb-8">
          <v-col cols="12" md="6">
            <h1 class="text--secondary">
              Takeaway Wait Time <span class="red--text">*</span>
            </h1>
            <p>
              Set an average wait time of how long it takes for you to get your
              orders ready for collection.
            </p>
          </v-col>
          <v-col cols="12" offset-md="6" md="6">
            <v-text-field
              class="required"
              prepend-icon="mdi-timer-sand"
              label="Wait Time (minutes)"
              clearable
              v-model="value.takeaway.wait_time"
              type="number"
              step="5"
              min="0"
              :rules="rules_wait_time"
            />
          </v-col>
        </v-row>
        <v-row class="justify-space-between settings-box pa-4 mb-8">
          <v-col cols="12" md="6">
            <h1 class="text--secondary">
              Pre-order Settings <span class="red--text">*</span>
            </h1>
            Our default pre-order settings are set to 7 days as a minimum. If
            you do not wish to let your customers pre-order and prefer a
            day-to-day takeaway service, you can take control easily by using
            your takeaway toggle to activate and de-activate your service
            instantly.
          </v-col>
          <v-col cols="12" offset-md="6" md="6">
            <v-text-field
              class="required"
              prepend-icon="mdi-calendar-alert"
              label="No. of Days Allowed to Order In Advance"
              v-model="value.takeaway.fulfilment_max_days"
              type="number"
              step="1"
              :rules="[field_req, val_0_7_21]"
              @input="
                (v) =>
                  Number(v) === 0
                    ? (value.takeaway.allow_future_scheduled_orders = false)
                    : (value.takeaway.allow_future_scheduled_orders = true)
              "
            />
          </v-col>
          <template v-if="value.takeaway.allow_future_scheduled_orders">
            <v-col cols="12">
              <h1 class="text--secondary">
                Takeaway Opening Hours <span class="red--text">*</span>
              </h1>
              <p>
                Choose your desired time slots and days of the week that you
                offer collection.
              </p>
            </v-col>
            <v-col cols="12" offset-md="6" md="6">
              <v-select
                class="mb-4 required"
                prepend-icon="mdi-calendar-check"
                :rules="[is_not_empty_array]"
                label="Days You Offer Collection"
                v-model="selected_days_takeaway"
                :items="
                  Object.values(days).slice(1).concat(Object.values(days)[0])
                "
                multiple
                required
              ></v-select>

              <div
                v-if="selected_days_arr.length > 0"
                class="grey lighten-4 pa-4 rounded"
              >
                <p class="font-weight-bold" v-if="selected_days_arr.length > 0">
                  Takeaway Opening Hours <span class="red--text">*</span>
                </p>
                <FulfilmentTimeSlot
                  v-for="day_no in selected_days_arr"
                  :key="day_no"
                  @update:from="
                    (time) =>
                      $set(
                        value.takeaway.fulfilment_slots[day_no],
                        'from',
                        time
                      )
                  "
                  @update:to="
                    (time) =>
                      $set(value.takeaway.fulfilment_slots[day_no], 'to', time)
                  "
                  @update:cutoff="
                    (time) =>
                      $set(
                        value.takeaway.fulfilment_slots[day_no],
                        'cutoff',
                        time
                      )
                  "
                  :time_from="value.takeaway.fulfilment_slots[day_no].from"
                  :time_to="value.takeaway.fulfilment_slots[day_no].to"
                  :time_cutoff="value.takeaway.fulfilment_slots[day_no].cutoff"
                  :day_no="day_no"
                />
              </div>
            </v-col>
          </template>
        </v-row>
        <v-row class="justify-space-between settings-box pa-4 mb-8">
          <v-col cols="12">
            <h1 class="text--secondary">Minimum Spend for Customers</h1>
            <p>Set your minimum spends for all your collection orders.</p>
          </v-col>
          <v-col cols="12" offset-md="6" md="6">
            <v-text-field
              prepend-icon="mdi-currency-gbp"
              label="Minimum Takeaway Spend (£)"
              v-model="value.takeaway.minimum"
              type="number"
              step="0.01"
              min="0"
              required
            />
          </v-col>
        </v-row>
        <v-row class="justify-space-between settings-box pa-4 mb-8">
          <v-col cols="12">
            <h1 class="text--secondary">Checkout Messages for Customers</h1>
            <p>Engage with your customers before and after checkout.</p>
          </v-col>
          <v-col cols="12" offset-md="6" md="6">
            <v-textarea
              prepend-icon="mdi-help"
              label="Before Checkout Message"
              v-model="value.takeaway.msg_instructions"
              hint="Message will appear when customer is checking out. This is a great place to ask customers to respond in your notes for any special requests."
              maxlength="300"
              counter="300"
              filled
            />
          </v-col>
          <v-col cols="12" offset-md="6" md="6">
            <v-textarea
              prepend-icon="mdi-human-greeting"
              label="After Payment Message"
              v-model="value.takeaway.msg_postcheckout"
              hint="Message will appear when customer has completed payment. This is a great place to add a thank you message and maybe encourage your customers to tag you on social media!"
              maxlength="300"
              counter="300"
              filled
            />
          </v-col>
        </v-row>
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { VModelObjectMixin } from "@/components/mixins/InputMixins";
import FulfilmentTimeSlot from "@/views/dashboard/components/shop_settings/FulfilmentTimeSlot";

import {
  field_req,
  is_number,
  is_not_empty_array,
  rules_cost,
  rules_postcode,
  rules_cost_min_001,
  number_between,
  val_0_7_21,
} from "@/utils/form_val_rules";

import { SERVICE_TYPES_INFO } from "@/shared/services";
import { days } from "@/shared/const";
import invert from "lodash/invert";

export default {
  name: "TakeawaySettings",
  mixins: [VModelObjectMixin],
  components: {
    FulfilmentTimeSlot,
  },
  props: {
    selected_days: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      SERVICE_TYPES_INFO,
      days,
      is_not_empty_array,
      rules_cost,
      rules_postcode,
      rules_cost_min_001,
      number_between,
      val_0_7_21,
      field_req,
      rules_wait_time: [field_req, is_number],
    };
  },
  computed: {
    selected_days_takeaway: {
      get() {
        return this.selected_days;
      },
      set(value) {
        this.$emit("update:selected_days", value);
      },
    },
    selected_days_arr() {
      let inverted_days = invert(days);
      let output = [];

      this.selected_days_takeaway.forEach((day) => {
        output.push(Number(inverted_days[day]));
      });

      return output.sort();
    },
  },
  methods: {
    emit_switch_tab(tab) {
      this.$emit("switch_tab", tab);
    },
  },
};
</script>
