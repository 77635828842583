<template>
  <v-alert type="info" outlined color="primary" class="mb-0">
    <span class="black--text"
      >Welcome! Please kindly click
      <a href="http://support.wearelocals.co.uk/" target="_blank">here</a> to
      visit our fully interactive support guide to help you setup easily.</span
    >
  </v-alert>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "InductionAlert",
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters("AdminStore", ["is_directory_shop"]),
    has_completed_induction() {
      return this.value && !this.value.flags.includes("induction");
    },
    directory_shop_induction_checklist() {
      if (!this.value || this.has_completed_induction) return null;

      const induction_checklist =
        this.value.categories &&
        Object.keys(this.value.categories).length &&
        this.value.type &&
        this.value.location &&
        this.value.description_header;
      return !!induction_checklist;
    },
    shop_induction_checklist() {
      if (!this.value || this.has_completed_induction) return null;

      const induction_checklist =
        this.value.categories &&
        Object.keys(this.value.categories).length &&
        this.value.type &&
        this.value.location &&
        this.value.description_header &&
        this.value.acquire_items.service &&
        Object.keys(this.value.acquire_items.service).length;
      return !!induction_checklist;
    },
    induction_checklist() {
      if (this.is_directory_shop)
        return this.directory_shop_induction_checklist;
      return this.shop_induction_checklist;
    },
  },
  watch: {
    induction_checklist(value) {
      if (value) {
        this.$emit("induction-passed", this.value);
      }
    },
  },
  mounted() {
    const is_inducted = this.induction_checklist;
    if (is_inducted) {
      this.$emit("induction-passed", is_inducted);
    }
  },
};
</script>
