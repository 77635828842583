<template>
  <v-dialog
    v-model="dialog"
    scrollable
    :fullscreen="$vuetify.breakpoint.smAndDown"
    persistent
    width="60vw"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-on="on"
        class="required"
        label="Postcodes"
        autocomplete="new-password"
        @click="dialog = true"
        :value="`Selected ${selected_postcodes.length} postcodes`"
        :rules="[
          () =>
            selected_postcodes.length ? true : 'Select at least one postcode',
        ]"
        required
      ></v-text-field>
    </template>

    <v-card v-if="dialog" class="action-modal br-16">
      <v-card-text>
        <v-text-field
          class="mb-4"
          label="Search Postcode"
          hint="Type first 3 characters of UK postcode e.g. 'BS3' to filter"
          persistent-hint
          autocomplete="new-password"
          @input="handle_search"
        ></v-text-field>
        <v-container class="action-modal-content" style="max-height: 60vh">
          <v-row>
            <v-col cols="12" md="3">
              <p>Selected {{ selected_postcodes.length }} postcodes</p>
              <v-chip
                class="mr-2 mb-2"
                :class="{
                  'purple lighten-3': pc
                    .toLowerCase()
                    .includes(postcode_search),
                }"
                v-for="pc in selected_postcodes"
                :key="`pc_${pc}`"
                >{{ pc }}</v-chip
              >
            </v-col>
            <v-col cols="12" md="9">
              <v-treeview
                v-model="selected_postcodes"
                ref="tree"
                :open.sync="open"
                :search="postcode_search"
                :items="items"
                selectable
                selection-type="leaf"
                item-key="name"
              ></v-treeview>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions class="justify-end">
        <v-btn color="black" rounded text @click="cancel">Cancel</v-btn>
        <v-btn color="primary" rounded elevation="0" @click="close">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import debounce from "lodash/debounce";

const postcodes_by_region = require("@/assets/data/postcodes_by_region.json");
export default {
  name: "PostcodeSelector",
  props: {
    postcodes: { type: Array, deafult: () => [] },
  },
  data() {
    return {
      dialog: false,
      open: [],
      postcode_search: null,
      selected_postcodes: [],
      items: null,
    };
  },
  mounted() {
    if (!this.items) {
      let items = [];

      Object.keys(postcodes_by_region)
        .sort()
        .forEach((region) => {
          items.push({
            name: region,
            children: this.format_region_for_treeview(
              postcodes_by_region[region]
            ),
          });
        });

      this.items = items;
    }
  },
  watch: {
    dialog(v) {
      if (v) this.selected_postcodes = this.postcodes;
    },
  },
  created() {
    this.selected_postcodes = this.postcodes;

    let self = this;
    this.handle_search = debounce(function (val) {
      if (val !== null && val.length >= 2) {
        self.postcode_search = val;
        self.$refs.tree.updateAll(true);
      } else {
        self.open = [];
        self.postcode_search = null;
      }
    }, 500);
  },
  methods: {
    close() {
      this.$emit("update", this.selected_postcodes);
      this.dialog = false;
      this.open = [];
    },
    cancel() {
      this.selected_postcodes = this.postcodes;
      this.dialog = false;
      this.open = [];
    },
    format_region_for_treeview(postcodes) {
      // Very weird function as Vue would exceed maximum call size
      // when processing such a huge dataset with recursion
      // HZ 24/03/21
      let ret = [];
      Object.keys(postcodes)
        .sort()
        .forEach((region) => {
          let children = [];

          Object.keys(postcodes[region])
            .sort()
            .forEach((sub_region) => {
              let grand_children = [];

              Object.keys(postcodes[region][sub_region])
                .sort()
                .forEach((sub_sub_region) => {
                  grand_children.push({
                    name: sub_sub_region,
                  });
                });

              children.push({
                name: sub_region,
                children: postcodes[region][sub_region].map((name) => ({
                  name,
                })),
              });
            });

          ret.push({
            name: region,
            children: children,
          });
        });

      return ret;
    },
  },
};
</script>
