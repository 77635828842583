<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      <div class="d-block">
        <h3
          class="
            display-2
            d-flex
            align-center
            text--secondary
            mb-4
            text-center text-md-left
          "
        >
          Delivery
        </h3>
        <v-row class="justify-space-between">
          <v-col cols="12" md="6">
            <v-checkbox
              color="primary"
              v-model="value.acquire_items.service.hospitality_delivery"
              hide-details="auto"
              class="ma-0 d-inline-block"
              v-on:click.stop.prevent
              @change="(v) => $emit('accept-service', v)"
            >
              <template v-slot:label>
                <p class="text-h4 font-weight-bold ma-0 pa-0">
                  Accept Orders for Delivery
                </p>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
      </div>
      <template v-slot:actions>
        <v-icon large>mdi-chevron-down</v-icon>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <div v-if="value.acquire_items.service.hospitality_delivery" class="pt-4">
        <v-row class="justify-space-between settings-box pa-4 mb-8">
          <v-col cols="12" md="6">
            <h1 class="text--secondary">
              Delivery Wait Time <span class="red--text">*</span>
            </h1>
            <p>
              Set an average wait time of how long it takes to deliver your
              orders to your customers.
            </p>
          </v-col>
          <v-col cols="12" offset-md="6" md="6">
            <v-text-field
              class="required"
              prepend-icon="mdi-timer-sand"
              label="Wait Time (minutes)"
              clearable
              v-model="value.hospitality_delivery.wait_time"
              type="number"
              step="5"
              min="0"
              :rules="rules_wait_time"
            />
          </v-col>
        </v-row>
        <v-row class="justify-space-between settings-box pa-4 mb-8">
          <v-col cols="12" md="6">
            <h1 class="text--secondary">
              Pre-order Settings <span class="red--text">*</span>
            </h1>
            Our pre-order days are set to 7 days as default. If you do not wish
            to offer a pre-order service, please set your pre-order days to 0.
            <br />TIP: You can also tap on the active/de-active delivery toggle
            to turn your service on and off at anytime.
          </v-col>
          <v-col cols="12" offset-md="6" md="6">
            <v-text-field
              class="required"
              prepend-icon="mdi-calendar-alert"
              label="No. of Days Allowed to Order In Advance"
              v-model="value.hospitality_delivery.fulfilment_max_days"
              type="number"
              step="1"
              :rules="[field_req, val_0_7_21]"
              @input="
                (v) =>
                  Number(v) === 0
                    ? (value.hospitality_delivery.allow_future_scheduled_orders = false)
                    : (value.hospitality_delivery.allow_future_scheduled_orders = true)
              "
            />
          </v-col>

          <template
            v-if="value.hospitality_delivery.allow_future_scheduled_orders"
          >
            <v-col cols="12" md="6">
              <h1 class="text--secondary">
                Delivery Opening Hours <span class="red--text">*</span>
              </h1>
              <p>
                Choose your desired time slots and days of the week that you
                offer delivery.
              </p>
            </v-col>
            <v-col cols="12" offset-md="6" md="6">
              <v-select
                class="mb-4 required"
                prepend-icon="mdi-calendar-check"
                label="Days You Offer Delivery"
                v-model="selected_days_hospitality_delivery"
                :items="
                  Object.values(days).slice(1).concat(Object.values(days)[0])
                "
                hint="Use it to enable a calendar at the checkout for customers to pick a date when they'd like to receive the goods. Calendar availability: any date within a fortnight from purchase, according to your selected days."
                persistent-hint
                multiple
                :rules="[is_not_empty_array]"
              ></v-select>
              <div
                v-if="selected_days_arr.length > 0"
                class="grey lighten-4 blue pa-4 md-ml-8 rounded"
              >
                <p class="font-weight-bold" v-if="selected_days_arr.length > 0">
                  Delivery Opening Hours <span class="red--text">*</span>
                </p>
                <FulfilmentTimeSlot
                  v-for="day_no in selected_days_arr"
                  :key="day_no"
                  @update:from="
                    (time) =>
                      $set(
                        value.hospitality_delivery.fulfilment_slots[day_no],
                        'from',
                        time
                      )
                  "
                  @update:to="
                    (time) =>
                      $set(
                        value.hospitality_delivery.fulfilment_slots[day_no],
                        'to',
                        time
                      )
                  "
                  @update:cutoff="
                    (time) =>
                      $set(
                        value.hospitality_delivery.fulfilment_slots[day_no],
                        'cutoff',
                        time
                      )
                  "
                  :time_from="
                    value.hospitality_delivery.fulfilment_slots[day_no].from
                  "
                  :time_to="
                    value.hospitality_delivery.fulfilment_slots[day_no].to
                  "
                  :time_cutoff="
                    value.hospitality_delivery.fulfilment_slots[day_no].cutoff
                  "
                  :day_no="day_no"
                />
              </div>
            </v-col>
          </template>
        </v-row>

        <v-row class="justify-space-between settings-box pa-4 mb-8">
          <v-col cols="12">
            <h1 class="text--secondary">
              Delivery Fee & Minimum Spendings <span class="red--text">*</span>
            </h1>
            <p>
              Set your delivery fee and minimum spendings for all of your
              orders.
            </p>
          </v-col>
          <v-col cols="12" offset-md="6" md="6">
            <v-text-field
              class="required"
              prepend-icon="mdi-currency-gbp"
              label="Standard Delivery Fee (£)"
              v-model="value.hospitality_delivery.fee"
              type="number"
              step="0.01"
              min="0"
              :rules="rules_cost"
              required
            />
          </v-col>
          <v-col cols="12" offset-md="6" md="6">
            <v-text-field
              prepend-icon="mdi-currency-gbp"
              label="Minimum Delivery Spend (£)"
              v-model="value.hospitality_delivery.minimum"
              type="number"
              step="0.01"
              min="0"
              required
            />
          </v-col>
          <v-col cols="12" offset-md="6" md="6">
            <v-checkbox
              color="primary"
              v-model="free_delivery_enabled"
              hide-details="auto"
              class="d-inline-block"
            >
              <template v-slot:label>
                <h4>Enable Free Delivery Spend</h4>
              </template></v-checkbox
            >
          </v-col>
          <v-col cols="12" offset-md="6" md="6" v-if="free_delivery_enabled">
            <v-text-field
              class="required"
              :prepend-icon="SERVICE_TYPES_INFO.hospitality_delivery.icon"
              label="Free Delivery Spend (£) *"
              v-model="value.hospitality_delivery.free_after"
              type="number"
              step="0.01"
              min="0.01"
              :rules="rules_cost_min_001"
              required
            />
          </v-col>
        </v-row>
        <v-row class="justify-space-between settings-box pa-4 mb-8">
          <v-col cols="12">
            <h1 class="text--secondary">
              Delivery Areas <span class="red--text">*</span>
            </h1>
            <p>
              Choose your delivery areas and set your postcodes-based delivery
              fees.
            </p>
          </v-col>
          <v-col cols="12" offset-md="6" md="6">
            <v-checkbox
              color="primary"
              v-model="value.hospitality_delivery.accept_every_postcode"
              hide-details="auto"
              class="d-inline-block"
            >
              <template v-slot:label>
                <h4>Accept All Postcodes</h4>
              </template>
            </v-checkbox>
          </v-col>
          <v-col
            cols="12"
            offset-md="6"
            md="6"
            v-if="!value.hospitality_delivery.accept_every_postcode"
          >
            <PostcodeSelector
              :postcodes="value.hospitality_delivery.accepted_postcodes"
              @update="
                (postcodes) =>
                  $set(
                    value.hospitality_delivery,
                    'accepted_postcodes',
                    postcodes
                  )
              "
            />
          </v-col>

          <v-col class="d-flex" cols="12" offset-md="6" md="6">
            <v-checkbox
              class="ma-0"
              color="primary"
              v-model="value.hospitality_delivery.is_using_tiers"
              hide-details="auto"
            >
              <template v-slot:label>
                <h4>Enable Specific Postcode-based Delivery Fees</h4>
              </template>
            </v-checkbox>
          </v-col>
          <v-col
            cols="12"
            offset-md="6"
            md="6"
            v-if="value.hospitality_delivery.is_using_tiers"
          >
            <v-alert class="my-4" type="info" color="purple" outlined block>
              <template v-if="!value.hospitality_delivery.accept_every_postcode"
                >Set specific fees against your selected postcodes
                above.</template
              >
              Please note: the standard delivery fee that you've set will apply
              to postcodes that are not selected here.
            </v-alert>
          </v-col>
          <v-col
            v-if="value.hospitality_delivery.is_using_tiers"
            cols="12"
            offset-md="6"
            md="6"
          >
            <v-col class="rounded pa-0" cols="12">
              <DeliveryFeeTierSetting
                v-for="(tier, index) in value.hospitality_delivery.fees"
                :accept-every-postcode="
                  value.hospitality_delivery.accept_every_postcode
                "
                :available-postcodes="
                  value.hospitality_delivery.accepted_postcodes
                "
                :tier="tier"
                :key="index"
                @update:fee="
                  (fee) => (value.hospitality_delivery.fees[index].fee = fee)
                "
                @update:postcodes="
                  (postcodes) =>
                    (value.hospitality_delivery.fees[index].postcodes =
                      postcodes)
                "
                @delete="delete_tier(index)"
              />
            </v-col>
            <div class="text-right mb-4">
              <v-btn
                v-if="value.hospitality_delivery.is_using_tiers"
                color="primary"
                right
                small
                @click="add_tier"
              >
                + Add Fee Tier</v-btn
              >
            </div>
          </v-col>
        </v-row>

        <v-row class="justify-space-between settings-box pa-4 mb-8">
          <v-col cols="12">
            <h1 class="text--secondary">Checkout Messages for Customers</h1>
            <p>Engage with your customers before and after checkout.</p>
          </v-col>

          <v-col cols="12" offset-md="6" md="6">
            <v-textarea
              prepend-icon="mdi-help"
              label="Before Checkout Message"
              v-model="value.hospitality_delivery.msg_instructions"
              hint="Message will appear when customer is checking out. This is a great place to ask customers to respond in your notes for any special requests."
              maxlength="300"
              counter="300"
              filled
            />
          </v-col>
          <v-col cols="12" offset-md="6" md="6">
            <v-textarea
              prepend-icon="mdi-human-greeting"
              label="After Payment Message"
              v-model="value.hospitality_delivery.msg_postcheckout"
              hint="Message will appear when customer has completed payment. This is a great place to add a thank you message and maybe encourage your customers to tag you on social media!"
              maxlength="300"
              counter="300"
              filled
            />
          </v-col>
        </v-row>
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { VModelObjectMixin } from "@/components/mixins/InputMixins";
import {
  field_req,
  is_number,
  is_not_empty_array,
  rules_cost,
  rules_postcode,
  rules_cost_min_001,
  number_between,
  val_min_7,
  val_max_21,
  val_0_7_21,
} from "@/utils/form_val_rules";

import { SERVICE_TYPES_INFO } from "@/shared/services";
import { days } from "@/shared/const";
import invert from "lodash/invert";

import DeliveryFeeTierSetting from "@/views/dashboard/components/shop_settings/DeliveryFeeTierSetting";
import FulfilmentTimeSlot from "@/views/dashboard/components/shop_settings/FulfilmentTimeSlot";
import PostcodeSelector from "@/views/dashboard/components/shop_settings/PostcodeSelector";

export default {
  name: "HospitalityDeliveryOrdersSettings",
  mixins: [VModelObjectMixin],
  components: {
    DeliveryFeeTierSetting,
    FulfilmentTimeSlot,
    PostcodeSelector,
  },
  props: {
    selected_days: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      SERVICE_TYPES_INFO,
      days,
      is_not_empty_array,
      rules_cost,
      rules_postcode,
      rules_cost_min_001,
      number_between,
      field_req,
      val_min_7,
      val_max_21,
      val_0_7_21,
      rules_wait_time: [field_req, is_number],
      free_delivery_enabled: null,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.free_delivery_enabled = !!value?.hospitality_delivery?.free_after;
      },
    },
    free_delivery_enabled: function (value) {
      const shop_data = this.value;
      if (!value) {
        this.$emit("input", {
          ...shop_data,
          hospitality_delivery: {
            ...shop_data.hospitality_delivery,
            free_after: null,
          },
        });
      }
    },
  },
  computed: {
    selected_days_hospitality_delivery: {
      get() {
        return this.selected_days;
      },
      set(value) {
        this.$emit("update:selected_days", value);
      },
    },
    selected_days_arr() {
      let inverted_days = invert(days);
      let output = [];

      this.selected_days_hospitality_delivery.forEach((day) => {
        output.push(Number(inverted_days[day]));
      });

      return output.sort();
    },
  },
  created() {
    if (!this.value.hospitality_delivery.fees) {
      this.value.hospitality_delivery.fees = [{}];
    }
  },
  methods: {
    emit_switch_tab(tab) {
      this.$emit("switch_tab", tab);
    },
    remove_post_code(post_code) {
      let pc = this.value.hospitality_delivery.accepted_postcodes;
      pc.splice(
        pc.findIndex((_) => _ === post_code),
        1
      );
    },
    add_tier() {
      this.value.hospitality_delivery.fees.push({});
      this.$forceUpdate();
    },
    delete_tier(index) {
      if (this.value.hospitality_delivery.fees.length === 1) {
        this.value.hospitality_delivery.is_using_tiers = false;
      }
      this.value.hospitality_delivery.fees.splice(index, 1);
      this.$forceUpdate();
    },
  },
};
</script>
