var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"mb-2"},[_c('v-col',[_c('span',{staticClass:"text--secondary"},[_vm._v(" You already have the base T&Cs your shop needs but it is good practice to complete these sections. They are all visible to end users when they click Terms and Conditions on your shop. ")])])],1),_c('v-form',[_c('v-expansion-panels',{model:{value:(_vm.panel_index),callback:function ($$v) {_vm.panel_index=$$v},expression:"panel_index"}},_vm._l((_vm.legal_docs),function(doc,index){return _c('v-expansion-panel',{key:index},[_c('v-expansion-panel-header',{staticClass:"justify-space-between"},[_c('h3',[_vm._v(_vm._s(doc.label))]),_c('v-btn',{staticClass:"ml-auto flex-grow-0",attrs:{"text":"","right":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.open_legal(doc.id, doc.label, index)}}},[_vm._v("Edit")])],1),_c('v-expansion-panel-content',{staticClass:"legal-content-preview scroll-y"},[(doc.content)?[_c('p',{domProps:{"innerHTML":_vm._s(doc.content)}})]:[_c('p',[_vm._v("-")])]],2)],1)}),1)],1),_c('v-dialog',{attrs:{"max-width":"1200","fullscreen":_vm.$vuetify.breakpoint.mdAndDown},model:{value:(_vm.dialog_legal),callback:function ($$v) {_vm.dialog_legal=$$v},expression:"dialog_legal"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(_vm._s(_vm.current_legal_doc.label))]),_c('v-card-text',[_c('editor-menu-bar',{attrs:{"editor":_vm.editor},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var commands = ref.commands;
var isActive = ref.isActive;
return [_c('div',{staticClass:"tiptap-menu"},[_c('button',{staticClass:"menubar__button",class:{
                'is-active': isActive.heading({ level: 1 }),
              },on:{"click":function($event){return commands.heading({ level: 1 })}}},[_c('v-icon',[_vm._v("mdi-format-header-1")])],1),_c('button',{staticClass:"menubar__button",class:{
                'is-active': isActive.heading({ level: 2 }),
              },on:{"click":function($event){return commands.heading({ level: 2 })}}},[_c('v-icon',[_vm._v("mdi-format-header-2")])],1),_c('button',{staticClass:"menubar__button",class:{
                'is-active': isActive.heading({ level: 3 }),
              },on:{"click":function($event){return commands.heading({ level: 3 })}}},[_c('v-icon',[_vm._v("mdi-format-header-3")])],1),_c('button',{staticClass:"menubar__button",class:{ 'is-active': isActive.paragraph() },on:{"click":commands.paragraph}},[_c('v-icon',[_vm._v("mdi-format-paragraph")])],1),_c('button',{staticClass:"menubar__button",class:{ 'is-active': isActive.bold() },on:{"click":commands.bold}},[_c('v-icon',[_vm._v("mdi-format-bold")])],1),_c('button',{staticClass:"menubar__button",class:{ 'is-active': isActive.italic() },on:{"click":commands.italic}},[_c('v-icon',[_vm._v("mdi-format-italic")])],1),_c('button',{staticClass:"menubar__button",class:{ 'is-active': isActive.underline() },on:{"click":commands.underline}},[_c('v-icon',[_vm._v("mdi-format-underline")])],1),_c('button',{staticClass:"menubar__button",class:{ 'is-active': isActive.bullet_list() },on:{"click":commands.bullet_list}},[_c('v-icon',[_vm._v("mdi-format-list-bulleted")])],1),_c('button',{staticClass:"menubar__button",class:{ 'is-active': isActive.ordered_list() },on:{"click":commands.ordered_list}},[_c('v-icon',[_vm._v("mdi-format-list-numbered")])],1),_c('button',{staticClass:"menubar__button",on:{"click":commands.undo}},[_c('v-icon',[_vm._v("undo")])],1),_c('button',{staticClass:"menubar__button",on:{"click":commands.redo}},[_c('v-icon',[_vm._v("redo")])],1)])]}}])}),_c('editor-content',{staticClass:"tiptap",attrs:{"editor":_vm.editor}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.close_legal()}}},[_vm._v(" close ")]),_c('v-btn',{attrs:{"color":"primary darken-1"},on:{"click":function($event){return _vm.add_legal()}}},[_vm._v(" Save ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }