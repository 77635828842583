<template>
  <div>
    <v-row v-if="is_directory_shop">
      <v-col cols="12" md="6">
        <v-alert type="info" outlined color="primary" class="mb-0">
          <span class="black--text"
            >Please note this is a directory only shop and cannot accept orders
            but you are free to discover all the possibilities of Order
            Platform. If you'd like to extend your shop functionality please
            contact {{ brand_contact || "support@wearelocals.co.uk" }}</span
          >
        </v-alert>
      </v-col>
    </v-row>
    <v-row class="justify-space-between">
      <v-col cols="12" class="pb-0">
        <h1 class="text--secondary">Order Checkout Notes</h1>
        <p class="mb-0">
          Add a placeholder text in your checkout note box for your customers.
        </p>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          prepend-icon="mdi-help"
          label="Add placeholder text for your checkout note box"
          v-model="value.acquire_items.note_placeholder"
          hint="Customers can add notes at the checkout, use it to suggest other details that would help you, e.g. allergies"
          maxlength="200"
          counter="200"
        />
      </v-col>
      <v-col cols="12" class="pb-0">
        <h1 class="text--secondary">Order Checkout Fee</h1>
        <p class="mb-0">
          Please note that if you add this fee, it will apply to all your
          activated services.
        </p>
      </v-col>
      <v-col cols="12" md="6">
        <v-checkbox
          color="primary"
          label="Add Custom Order Fee"
          v-model="custom_fee_enabled"
          hide-details="auto"
          class="d-inline-block mb-4"
        />
        <v-text-field
          v-if="custom_fee_enabled"
          class="required"
          prepend-icon="mdi-currency-gbp"
          label="Custom Fee (£)"
          v-model="value.custom_fee.fee"
          type="number"
          step="0.01"
          min="0.01"
          :rules="rules_cost_min_001"
          required
        />
        <v-text-field
          v-if="custom_fee_enabled"
          class="required"
          prepend-icon="mdi-shape-outline"
          label="Name Custom Fee"
          placeholder="Eg. Packaging Fee"
          v-model="value.custom_fee.name"
          :rules="rules_txt_req"
          maxlength="50"
          counter="50"
          required
        />
      </v-col>
      <v-col cols="12" class="pb-0">
        <h1 class="text--secondary">Order Tip</h1>
        <p class="mb-0">Allow customers to add a tip with their orders.</p>
      </v-col>
      <v-col cols="12" md="6" class="mb-4">
        <v-checkbox
          v-model="value.allow_tip"
          color="primary"
          label="Add Tipping Option"
          hide-details="auto"
          class="d-inline-block"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import {
  field_req,
  txt_min_2_char,
  is_number,
  count_2_decimals,
  val_min_001,
} from "@/utils/form_val_rules";

import { VModelObjectMixin } from "@/components/mixins/InputMixins";

export default {
  name: "OrdersSettings",
  mixins: [VModelObjectMixin],
  data() {
    return {
      rules_txt_req: [field_req, txt_min_2_char],
      rules_cost_min_001: [field_req, is_number, val_min_001, count_2_decimals],
      custom_fee_enabled: null,
    };
  },
  computed: {
    ...mapGetters("BrandStore", ["brand_contact"]),
    ...mapGetters("AdminStore", ["is_directory_shop"]),
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.custom_fee_enabled = !!value?.custom_fee?.fee;
      },
    },
    custom_fee_enabled: function (value) {
      if (!value) {
        this.$emit("input", {
          ...this.value,
          custom_fee: {
            fee: null,
            name: null,
          },
        });
      }
    },
  },
};
</script>
