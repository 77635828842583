<template>
  <div class="px-4 py-4">
    <template v-if="integration_status !== INTEGRATION_STATUS.COMPLETED">
      <h1 class="mb-2">Get paid by integrating with Stripe</h1>
      <p>
        Please click button below and follow Stripe registration form.
        Completing it will allow your customers to pay for your goods. If you
        completed your registration please wait, we will update this page in
        realtime, if you want to continue registtration process click button
        again.
      </p>
      <template v-if="integration_status === INTEGRATION_STATUS.PENDING">
        <v-btn
          color="primary"
          rounded
          @click="create_stripe_account"
          :loading="loading"
          :disabled="loading"
          ><span class="no-text-transform">Integrate with Stripe</span></v-btn
        >
      </template>
      <template
        v-else-if="integration_status === INTEGRATION_STATUS.IN_PROGRESS"
      >
        <v-btn
          color="primary"
          rounded
          @click="create_stripe_account_link"
          :loading="loading"
          :disabled="loading"
          ><span class="no-text-transform"
            >Continue Integrate with Stripe</span
          ></v-btn
        >
      </template>
    </template>
    <template v-else>
      <h1 class="mb-2">Payments with Stripe</h1>
      <p>
        You are sucesfully integrated with Stripe. Click button below to access
        your financial dashboard.
      </p>
      <v-btn
        color="primary"
        rounded
        @click="create_stripe_login_link"
        :loading="loading"
        :disabled="loading"
        ><span class="no-text-transform">Open Stripe Dashboard</span></v-btn
      >
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  create_stripe_account,
  create_stripe_account_link,
  create_stripe_login_link,
} from "@/requests";

const INTEGRATION_STATUS = {
  PENDING: "pending",
  IN_PROGRESS: "in_progress",
  COMPLETED: "completed",
};
export default {
  name: "StripePaymentsSettings",
  data() {
    return {
      INTEGRATION_STATUS,
      loading: false,
    };
  },
  computed: {
    ...mapState("AdminStore", ["shop_id"]),
    ...mapState("ShopStore", ["shop"]),
    shop_url() {
      const individual_brand = this.shop.brands.find(
        (b) => b.brand_type === "single_shop"
      );
      if (individual_brand) return this.to_brand_url(individual_brand);
      return this.to_brand_url(this.shop.brands[0]);
    },
    integration_status() {
      const stripe_account = this.shop.stripe_connect;
      let status = INTEGRATION_STATUS.PENDING;

      if (stripe_account) {
        if (stripe_account.details_submitted && stripe_account.charges_enabled)
          status = INTEGRATION_STATUS.COMPLETED;
        else status = INTEGRATION_STATUS.IN_PROGRESS;
      }
      return status;
    },
  },
  methods: {
    to_brand_url(brand) {
      if (brand.brand_type === "single_shop") return brand.url;
      return `${brand.url}shop/${this.shop_id}`;
    },
    async create_stripe_account() {
      this.loading = true;
      try {
        await create_stripe_account({
          business_name: this.shop.name,
          business_url: this.shop_url,
          shop_id: this.shop_id,
        });
        const {
          data: { url },
        } = await create_stripe_account_link({
          shop_id: this.shop_id,
          type: "account_onboarding",
        });
        Object.assign(document.createElement("a"), {
          target: "_blank",
          href: url,
        }).click();
      } finally {
        this.loading = false;
      }
    },
    async create_stripe_account_link() {
      this.loading = true;
      try {
        const {
          data: { url },
        } = await create_stripe_account_link({
          shop_id: this.shop_id,
          type: "account_onboarding",
        });
        Object.assign(document.createElement("a"), {
          target: "_blank",
          href: url,
        }).click();
      } finally {
        this.loading = false;
      }
    },
    async create_stripe_login_link() {
      this.loading = true;
      try {
        const {
          data: { url },
        } = await create_stripe_login_link({
          shop_id: this.shop_id,
        });
        Object.assign(document.createElement("a"), {
          target: "_blank",
          href: url,
        }).click();
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
