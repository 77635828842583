<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      <div class="d-block">
        <h3
          class="
            display-2
            d-flex
            align-center
            text--secondary
            mb-4
            text-center text-md-left
          "
        >
          Delivery
        </h3>
        <v-row class="justify-space-between">
          <v-col cols="12" md="6">
            <v-checkbox
              color="primary"
              v-model="value.acquire_items.service.delivery"
              hide-details="auto"
              class="ma-0 d-inline-block"
              v-on:click.stop.prevent
              @change="(v) => $emit('accept-service', v)"
            >
              <template v-slot:label>
                <p class="text-h4 font-weight-bold ma-0 pa-0">
                  Accept Orders for Delivery
                </p>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
      </div>
      <template v-slot:actions>
        <v-icon large> mdi-chevron-down</v-icon>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <div v-if="value.acquire_items.service.delivery" class="pt-4">
        <v-row class="justify-space-between settings-box pa-4 mb-8">
          <v-col cols="12">
            <h1 class="text--secondary">
              Delivery Fee & Minimum Spendings <span class="red--text">*</span>
            </h1>
            <p>
              Set your delivery fee and minimum spendings for all of your
              orders.
            </p>
          </v-col>
          <v-col cols="12" offset-md="6" md="6">
            <v-text-field
              class="required"
              prepend-icon="mdi-currency-gbp"
              label="Standard Delivery Fee (£)"
              v-model="value.delivery.fee"
              type="number"
              step="0.01"
              min="0"
              :rules="rules_cost"
              required
            />
          </v-col>
          <v-col cols="12" offset-md="6" md="6">
            <v-text-field
              class="required"
              prepend-icon="mdi-currency-gbp"
              label="Minimum Delivery Spend (£)"
              v-model="value.delivery.minimum"
              type="number"
              step="0.01"
              min="0"
              :rules="rules_cost"
              required
            />
          </v-col>
          <v-col cols="12" offset-md="6" md="6">
            <v-checkbox
              color="primary"
              v-model="free_delivery_enabled"
              hide-details="auto"
              class="ma-0"
            >
              <template v-slot:label>
                <h4>Enable Free Delivery Spend</h4>
              </template></v-checkbox
            >
          </v-col>
          <v-col cols="12" offset-md="6" md="6" v-if="free_delivery_enabled">
            <v-text-field
              class="required"
              :prepend-icon="SERVICE_TYPES_INFO.delivery.icon"
              label="Free Delivery Spend (£)"
              v-model="value.delivery.free_after"
              type="number"
              step="0.01"
              min="0.01"
              :rules="rules_cost_min_001"
              required
            />
          </v-col>
        </v-row>

        <v-row class="justify-space-between settings-box pa-4 mb-8">
          <v-col cols="12" md="6">
            <h1 class="text--secondary">
              Pre-order Settings <span class="red--text">*</span>
            </h1>
            <p>
              If your customers do not need a select a delivery date during
              checkout, set it as 0 days. Please note that you can also easily
              take control by using your delivery toggle to activate and
              de-activate your service instantly.
            </p>
          </v-col>
          <v-col cols="12" offset-md="6" md="6">
            <v-text-field
              class="required"
              prepend-icon="mdi-calendar-alert"
              label="No. of Days Allowed to Order In Advance"
              v-model="value.delivery.fulfilment_max_days"
              persistent-hint
              :rules="[field_req, val_0_7_21]"
              type="number"
              @input="
                (v) =>
                  Number(v) === 0
                    ? (value.delivery.allow_future_scheduled_orders = false)
                    : (value.delivery.allow_future_scheduled_orders = true)
              "
            />
          </v-col>
          <template v-if="Number(value.delivery.fulfilment_max_days)">
            <v-col cols="12">
              <h1 class="text--secondary">
                Delivery Days Settings <span class="red--text">*</span>
              </h1>
              <p>
                Choose your desired days of the week that you offer delivery
                service
              </p>
            </v-col>
            <v-col cols="12" offset-md="6" md="6">
              <v-select
                class="required"
                prepend-icon="mdi-calendar-check"
                label="Days You Offer Delivery"
                v-model="selected_days_delivery"
                :items="
                  Object.values(days).slice(1).concat(Object.values(days)[0])
                "
                :rules="[is_not_empty_array]"
                persistent-hint
                multiple
              ></v-select>
            </v-col>
          </template>
        </v-row>
        <v-row class="justify-space-between settings-box pa-4 mb-8">
          <v-col cols="12">
            <h1 class="text--secondary">
              Delivery Areas <span class="red--text">*</span>
            </h1>
            <p>
              Choose your delivery areas and set your postcodes-based delivery
              fees.
            </p>
          </v-col>
          <v-col cols="12" offset-md="6" md="6">
            <div class="d-inline-block">
              <v-checkbox
                color="primary"
                v-model="value.delivery.accept_every_postcode"
                hide-details="auto"
                class="ma-0"
              >
                <template v-slot:label>
                  <h4>Accept All Postcodes</h4>
                </template>
              </v-checkbox>
            </div>
          </v-col>
          <v-col
            cols="12"
            offset-md="6"
            md="6"
            v-if="!value.delivery.accept_every_postcode"
          >
            <PostcodeSelector
              :postcodes="value.delivery.accepted_postcodes"
              @update="
                (postcodes) =>
                  $set(value.delivery, 'accepted_postcodes', postcodes)
              "
            />
          </v-col>
          <v-col class="d-flex" cols="12" offset-md="6" md="6">
            <v-checkbox
              class="ma-0"
              color="primary"
              v-model="value.delivery.is_using_tiers"
              hide-details="auto"
            >
              <template v-slot:label>
                <h4>Enable Specific Postcode-based Delivery Fees</h4>
              </template>
            </v-checkbox>
          </v-col>
          <v-col
            cols="12"
            offset-md="6"
            md="6"
            v-if="value.delivery.is_using_tiers"
          >
            <v-alert class="my-4" type="info" color="purple" outlined block>
              <template v-if="!value.delivery.accept_every_postcode"
                >Set specific fees against your selected postcodes
                above.</template
              >
              Note: the standard delivery fee that you've set will apply to
              postcodes that are not selected here.
            </v-alert>
          </v-col>
          <v-col
            v-if="value.delivery.is_using_tiers"
            cols="12"
            offset-md="6"
            md="6"
          >
            <div class="text-right mb-4">
              <v-btn
                class="primary-alt"
                v-if="value.delivery.is_using_tiers"
                rounded
                outlined
                color="primary"
                right
                small
                @click="add_tier"
              >
                <span class="black--text">+ Add Fee Tier</span></v-btn
              >
            </div>
            <DeliveryFeeTierSetting
              v-for="(tier, index) in value.delivery.fees"
              :tier="tier"
              :key="index"
              :accept-every-postcode="value.delivery.accept_every_postcode"
              :available-postcodes="value.delivery.accepted_postcodes"
              @update:fee="(fee) => (value.delivery.fees[index].fee = fee)"
              @update:postcodes="
                (postcodes) =>
                  (value.delivery.fees[index].postcodes = postcodes)
              "
              @delete="delete_tier(index)"
            />
          </v-col>
        </v-row>

        <v-row class="justify-space-between settings-box pa-4 mb-8">
          <v-col cols="12">
            <h1 class="text--secondary">Checkout Messages for Customers</h1>
            <p>Engage with your customers before and after checkout.</p>
          </v-col>
          <v-col cols="12" offset-md="6" md="6">
            <v-textarea
              prepend-icon="mdi-help"
              label="Before Checkout Message"
              v-model="value.delivery.msg_instructions"
              hint="Message will appear when customer is checking out. This is a great place to ask customers to respond in your notes for any special requests."
              maxlength="300"
              counter="300"
              filled
            />
          </v-col>
          <v-col cols="12" offset-md="6" md="6">
            <v-textarea
              prepend-icon="mdi-human-greeting"
              label="After Payment Message"
              v-model="value.delivery.msg_postcheckout"
              hint="Message will appear when customer has completed payment. This is a great place to add a thank you message and maybe encourage your customers to tag you on social media!"
              maxlength="300"
              counter="300"
              filled
            />
          </v-col>
        </v-row>
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { VModelObjectMixin } from "@/components/mixins/InputMixins";
import { SERVICE_TYPES_INFO } from "@/shared/services";
import { days } from "@/shared/const";
import {
  rules_cost,
  rules_postcode,
  rules_cost_min_001,
  number_between,
  field_req,
  is_not_empty_array,
  val_0_7_21,
} from "@/utils/form_val_rules";
import DeliveryFeeTierSetting from "@/views/dashboard/components/shop_settings/DeliveryFeeTierSetting";
import PostcodeSelector from "@/views/dashboard/components/shop_settings/PostcodeSelector";

export default {
  name: "DeliveryOrderSettings",
  mixins: [VModelObjectMixin],
  props: {
    selected_days: {
      type: Array,
      required: true,
    },
  },
  components: { DeliveryFeeTierSetting, PostcodeSelector },
  data() {
    return {
      SERVICE_TYPES_INFO,
      number_between,
      rules_cost,
      rules_postcode,
      rules_cost_min_001,
      field_req,
      is_not_empty_array,
      val_0_7_21,
      days,
      free_delivery_enabled: null,
    };
  },
  computed: {
    selected_days_delivery: {
      get() {
        return this.selected_days;
      },
      set(value) {
        this.$emit("update:selected_days", value);
      },
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.free_delivery_enabled = !!value?.delivery?.free_after;
      },
    },
    free_delivery_enabled: function (value) {
      const shop_data = this.value;
      if (!value) {
        this.$emit("input", {
          ...shop_data,
          delivery: { ...shop_data.delivery, free_after: null },
        });
      }
    },
  },
  created() {
    if (!this.value.delivery.fees) {
      this.value.delivery.fees = [{}];
    }
  },
  methods: {
    remove_post_code(post_code) {
      let pc = this.value.delivery.accepted_postcodes;
      pc.splice(
        pc.findIndex((_) => _ === post_code),
        1
      );
    },
    add_tier() {
      this.value.delivery.fees.push({ fee: null });
      this.$forceUpdate();
    },
    delete_tier(index) {
      if (this.value.delivery.fees.length === 1) {
        this.value.delivery.is_using_tiers = false;
      }
      this.value.delivery.fees.splice(index, 1);
      this.$forceUpdate();
    },
  },
};
</script>
