<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      <div class="d-block">
        <h3
          class="
            display-2
            d-flex
            align-center
            text--secondary
            mb-4
            text-center text-md-left
          "
        >
          Collection
        </h3>
        <v-row class="justify-space-between">
          <v-col cols="12" md="6">
            <v-checkbox
              color="primary"
              v-model="value.acquire_items.service.collection"
              hide-details="auto"
              class="ma-0 d-inline-block"
              v-on:click.stop.prevent
              @change="(v) => $emit('accept-service', v)"
            >
              <template v-slot:label>
                <p class="text-h4 font-weight-bold ma-0 pa-0">
                  Accept Orders for Collection
                </p>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
      </div>
      <template v-slot:actions>
        <v-icon large> mdi-chevron-down</v-icon>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <div v-if="value.acquire_items.service.collection" class="pt-4">
        <v-row class="justify-space-between settings-box pa-4 mb-8">
          <v-col cols="12">
            <h1 class="text--secondary">
              Minimum Spend for Customers <span class="red--text">*</span>
            </h1>
            <p>Set your minimum spend for all of your collection orders.</p>
          </v-col>
          <v-col cols="12" offset-md="6" md="6">
            <v-text-field
              prepend-icon="mdi-format-title"
              class="required"
              label="Minimum Collection Spend (£)"
              v-model="value.collection.minimum"
              type="number"
              step="0.01"
              min="0"
              :rules="rules_cost"
              required
            />
          </v-col>
        </v-row>
        <v-row class="justify-space-between settings-box pa-4 mb-8">
          <v-col cols="12" md="6">
            <h1 class="text--secondary">
              Pre-order Settings <span class="red--text">*</span>
            </h1>
            <p>
              Our default pre-order settings are set to 7 days as a minimum. If
              you do not wish to let your customers pre-order and prefer a
              day-to-day collection service, you can take control easily by
              using your collection toggle to activate and de-activate your
              service instantly.
            </p>
          </v-col>
          <v-col cols="12" offset-md="6" md="6">
            <v-text-field
              class="required"
              prepend-icon="mdi-calendar-alert"
              label="No. of Days Allowed to Order In Advance"
              v-model="value.collection.fulfilment_max_days"
              persistent-hint
              type="number"
              step="1"
              min="1"
              max="21"
              :rules="[field_req, val_min_7, val_max_21]"
              required
            />
          </v-col>
          <v-col cols="12">
            <h1 class="text--secondary">
              Collection Opening Hours <span class="red--text">*</span>
            </h1>
            <p>
              Choose your desired time slots and days of the week that you offer
              collection.
            </p>
          </v-col>
          <v-col cols="12" offset-md="6" md="6">
            <v-select
              class="mb-4 required"
              prepend-icon="mdi-calendar-check"
              :rules="[is_not_empty_array]"
              label="Days You Offer Collection"
              v-model="selected_days_collection"
              :items="
                Object.values(days).slice(1).concat(Object.values(days)[0])
              "
              multiple
              required
              @input="
                (v) =>
                  Number(v) === 0
                    ? (value.hospitality_delivery.allow_future_scheduled_orders = false)
                    : (value.hospitality_delivery.allow_future_scheduled_orders = true)
              "
            ></v-select>

            <div
              v-if="selected_days_arr.length > 0"
              class="grey lighten-4 blue pa-4 ml-8 rounded"
            >
              <p class="font-weight-bold" v-if="selected_days_arr.length > 0">
                Collection Opening Hours <span class="red--text">*</span>
              </p>
              <FulfilmentTimeSlot
                v-for="day_no in selected_days_arr"
                :key="day_no"
                @update:from="
                  (time) =>
                    $set(
                      value.collection.fulfilment_slots[day_no],
                      'from',
                      time
                    )
                "
                @update:to="
                  (time) =>
                    $set(value.collection.fulfilment_slots[day_no], 'to', time)
                "
                @update:cutoff="
                  (time) =>
                    $set(
                      value.collection.fulfilment_slots[day_no],
                      'cutoff',
                      time
                    )
                "
                :time_from="value.collection.fulfilment_slots[day_no].from"
                :time_to="value.collection.fulfilment_slots[day_no].to"
                :time_cutoff="value.collection.fulfilment_slots[day_no].cutoff"
                :day_no="day_no"
              />
            </div>
          </v-col>
        </v-row>
        <v-row class="justify-space-between settings-box pa-4 mb-8">
          <v-col cols="12">
            <h1 class="text--secondary">Checkout Messages for Customers</h1>
            <p>Engage with your customers before and after checkout.</p>
          </v-col>
          <v-col cols="12" offset-md="6" md="6">
            <v-textarea
              prepend-icon="mdi-help"
              label="Before Checkout Message"
              v-model="value.collection.msg_instructions"
              hint="Message will appear when customer is checking out. This is a great place to ask customers to respond in your notes for any special requests."
              maxlength="300"
              counter="300"
              filled
            />
          </v-col>
          <v-col cols="12" offset-md="6" md="6">
            <v-textarea
              prepend-icon="mdi-human-greeting"
              label="After Payment Message"
              v-model="value.collection.msg_postcheckout"
              hint="Message will appear when customer has completed payment. This is a great place to add a thank you message and maybe encourage your customers to tag you on social media!"
              maxlength="300"
              counter="300"
              filled
            />
          </v-col>
        </v-row>
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { VModelObjectMixin } from "@/components/mixins/InputMixins";
import { days } from "@/shared/const";
import {
  rules_cost,
  field_req,
  is_not_empty_array,
  val_min_7,
  val_max_21,
} from "@/utils/form_val_rules";
import FulfilmentTimeSlot from "@/views/dashboard/components/shop_settings/FulfilmentTimeSlot";
import invert from "lodash/invert";

export default {
  name: "CollectionOrdersSettings",
  mixins: [VModelObjectMixin],
  components: { FulfilmentTimeSlot },
  props: {
    selected_days: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      days,
      rules_cost,
      field_req,
      val_min_7,
      val_max_21,
      is_not_empty_array,
    };
  },
  computed: {
    selected_days_collection: {
      get() {
        return this.selected_days;
      },
      set(value) {
        this.$emit("update:selected_days", value);
      },
    },
    selected_days_arr() {
      let inverted_days = invert(days);
      let output = [];

      this.selected_days_collection.forEach((day) => {
        output.push(Number(inverted_days[day]));
      });

      return output.sort();
    },
  },
  methods: {
    emit_switch_tab(tab) {
      this.$emit("switch_tab", tab);
    },
  },
};
</script>
