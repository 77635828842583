<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      <div class="d-block">
        <h3
          class="
            display-2
            d-flex
            align-center
            text--secondary
            mb-4
            text-center text-md-left
          "
        >
          In House
        </h3>
        <v-row class="justify-space-between">
          <v-col cols="12" md="5">
            <v-checkbox
              color="primary"
              v-model="value.acquire_items.service.in_house"
              hide-details="auto"
              class="ma-0 d-inline-block"
              v-on:click.stop.prevent
              @change="(v) => $emit('accept-service', v)"
            >
              <template v-slot:label>
                <p class="text-h4 font-weight-bold ma-0 pa-0">
                  Accept Orders for In-house Table Service
                </p>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
      </div>
      <template v-slot:actions>
        <v-icon large> mdi-chevron-down</v-icon>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <div v-if="value.acquire_items.service.in_house" class="pt-4">
        <v-row class="justify-space-between settings-box pa-4 mb-8">
          <v-col cols="12" md="6">
            <h1 class="text--secondary">
              Table Service Wait Times <span class="red--text">*</span>
            </h1>
            <p>
              Set average wait times of how long it takes for you to get your
              orders ready for your customers.
            </p>
          </v-col>
          <v-col cols="12" offset-md="6" md="6">
            <v-text-field
              class="required"
              clearable
              :rules="[field_req]"
              prepend-icon="mdi-timer-sand"
              label="Food Wait Time (minutes)"
              v-model="value.in_house.food_wait_time"
              type="number"
              step="1"
              min="0"
            />
          </v-col>
          <v-col cols="12" offset-md="6" md="6">
            <v-text-field
              class="required"
              clearable
              :rules="[field_req]"
              prepend-icon="mdi-timer-sand"
              label="Drink Wait Time (minutes)"
              v-model="value.in_house.drink_wait_time"
              type="number"
              step="1"
              min="0"
            />
          </v-col>
        </v-row>
        <v-row class="justify-space-between settings-box pa-4 mb-8">
          <v-col cols="12" md="6">
            <h1 class="text--secondary">
              Minimum Spend for Customers <span class="red--text">*</span>
            </h1>
            <p>Set your minimum spends for all your table orders.</p>
          </v-col>
          <v-col cols="12" offset-md="6" md="6">
            <v-text-field
              class="required"
              :rules="[field_req]"
              prepend-icon="mdi-currency-gbp"
              label="Minimum Table Order Spend (£)"
              v-model="value.in_house.minimum"
              type="number"
              step="0.01"
              min="0"
            />
          </v-col>
        </v-row>
        <v-row class="justify-space-between settings-box pa-4 mb-8">
          <v-col cols="12" md="6">
            <h1 class="text--secondary">Order Table Service</h1>
            <p>
              If you offer table service, enable this setting and let your
              customers put in their table number during checkout.
            </p>
          </v-col>
          <v-col cols="12" offset-md="6" md="6">
            <v-checkbox
              color="primary"
              v-model="value.in_house.table_service"
              hide-details="auto"
              class="d-inline-block"
            >
              <template v-slot:label>
                <h4>Enable Table Service</h4>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
        <v-row class="justify-space-between settings-box pa-4 mb-8">
          <v-col cols="12" md="6">
            <h1 class="text--secondary">Order Collection Point</h1>
            <p>
              If you don’t offer table service, enable this setting and guide
              your customers to your collection point.
            </p>
          </v-col>
          <v-col cols="12" offset-md="6" md="6">
            <v-checkbox
              color="primary"
              v-model="value.in_house.collection_point"
              hide-details="auto"
              class="d-inline-block"
            >
              <template v-slot:label>
                <h4>Enable Collection Point</h4>
              </template>
            </v-checkbox>
          </v-col>
          <v-col
            v-if="value.in_house.collection_point"
            cols="12"
            offset-md="6"
            md="6"
          >
            <v-textarea
              prepend-icon="mdi-help"
              label="Collection Point Instructions"
              v-model="value.in_house.collection_point_instructions"
              hint="Text will appear when customer is checking out. Let your customers know when and where to collect their orders from."
              maxlength="300"
              counter="300"
              filled
              height="100"
            />
          </v-col>
        </v-row>
        <v-row class="justify-space-between settings-box pa-4 mb-8">
          <v-col cols="12" md="6">
            <h1 class="text--secondary">Checkout Messages for Customers</h1>
            <p>Engage with your customers before and after checkout.</p>
          </v-col>
          <v-col cols="12" offset-md="6" md="6">
            <v-textarea
              prepend-icon="mdi-help"
              label="Before Checkout Message"
              v-model="value.in_house.msg_instructions"
              hint="Message will appear when customer is checking out. This is a great place to ask customers to respond in your notes for any special requests."
              maxlength="300"
              counter="300"
              filled
            />
          </v-col>
          <v-col cols="12" offset-md="6" md="6">
            <v-textarea
              prepend-icon="mdi-human-greeting"
              label="After Payment Message"
              v-model="value.in_house.msg_postcheckout"
              hint="Message will appear when customer has completed payment. This is a great place to add a thank you message and maybe encourage your customers to tag you on social media!"
              maxlength="300"
              counter="300"
              filled
            />
          </v-col>
        </v-row>
        <v-row class="justify-space-between settings-box pa-4 mb-8">
          <v-col cols="12" md="6">
            <h1 class="text--secondary">Capture Customer Contact Details</h1>
            <p>
              This is optional - enabling this would require your customers to
              submit everyone's contact details on the table when they order.
              Their data will be stored purely for contact tracing purposes for
              30-days, then deleted.
            </p>
          </v-col>
          <v-col cols="12" offset-md="6" md="6">
            <v-checkbox
              color="primary"
              v-model="value.in_house.capture_party_members"
              hide-details="auto"
              class="d-inline-block"
            >
              <template v-slot:label>
                <h4>Enable Data Capturing</h4>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { VModelObjectMixin } from "@/components/mixins/InputMixins";
import { field_req } from "@/utils/form_val_rules";

export default {
  name: "InHouseSettings",
  mixins: [VModelObjectMixin],
  data() {
    return {
      field_req,
    };
  },
};
</script>
