<template>
  <v-container fluid>
    <v-row>
      <v-col v-if="!merchant_id_ready || !password_is_ready" cols="12" md="6">
        <h1 class="text--secondary mb-2">Unify - 1.2% + 12p</h1>
        <v-dialog
          v-model="unify_dialog"
          width="unset"
          :fullscreen="$vuetify.breakpoint.xsOnly"
          :class="{ 'br-16': $vuetify.breakpoint.smAndUp }"
        >
          <template v-slot:activator="{ on }">
            <v-btn elevation="0" rounded color="primary" v-on="on"
              >Setup with UNYFI now</v-btn
            >
          </template>

          <v-card :class="{ 'br-16': $vuetify.breakpoint.smAndUp }">
            <v-card-title
              class="primary text-h3 font-weight-bold white--text pb-4"
            >
              Unify Payment Setup
            </v-card-title>

            <v-card-text>
              <p class="font-weight-bold">
                UNYFI payments cost 1.2% + 12p per transaction. Follow steps to
                set it up now.
              </p>
              <ol>
                <li class="mb-2">
                  Click
                  <a
                    href="https://share.hsforms.com/1nrdQYkuDQtGLrwf2QgVgHQ5jr16"
                    target="_blank"
                    class="display-1 font-weight-bold"
                    >here</a
                  >
                  to complete your UNYFI application form.
                </li>
                <li class="mb-2">
                  Create your online transaction account
                  <a
                    href="https://mms.unyfigateway.co.uk/Pages/PublicPages/RegisterMerchant.aspx?ResellerID=F586DB16-335E-446F-BBB5-8686F578A88A"
                    target="_blank"
                    class="display-1 font-weight-bold"
                    >here</a
                  >.
                </li>
                <li class="mb-2">
                  After verification checks are complete (this takes between 3-5
                  days) with the merchant bank, you will receive an eDoc to sign
                  through email.
                </li>
                <li class="mb-2">
                  Once eDoc has been signed, it will take around 1-2 days for
                  your UNYFI account to sync with your current dashboard and
                  you’ll be ready to trade!
                </li>
              </ol>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text rounded @click="unify_dialog = false">
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <h1 class="text--secondary mt-6 mb-2">Stripe - 2% + 22p</h1>
        <v-dialog
          v-model="stripe_dialog"
          width="unset"
          :fullscreen="$vuetify.breakpoint.xsOnly"
          :class="{ 'br-16': $vuetify.breakpoint.smAndUp }"
        >
          <template v-slot:activator="{ on }">
            <v-btn elevation="0" rounded color="primary" v-on="on"
              >Setup with Stripe now</v-btn
            >
          </template>

          <v-card :class="{ 'br-16': $vuetify.breakpoint.smAndUp }">
            <v-card-title
              class="primary text-h3 font-weight-bold white--text pb-4"
            >
              Stripe Payment Setup
            </v-card-title>

            <v-card-text>
              <p class="font-weight-bold">
                Stripe payments cost 2% + 22p per transaction. Follow steps to
                set it up now.
              </p>
              <ol>
                <li class="mb-2">
                  Click
                  <a
                    href="https://dashboard.stripe.com/"
                    class="display-1 font-weight-bold"
                    target="_blank"
                    >here</a
                  >
                  to log into Stripe or create a new Stripe account - make sure
                  you complete your email verification and fill in all of your
                  company details.
                </li>
                <li class="mb-2">
                  Once logged in, on your dashboard - click
                  <b class="display-1 font-weight-bold">Settings</b> > scroll
                  down to
                  <b class="display-1 font-weight-bold">Business Settings.</b>
                  Under
                  <b class="display-1 font-weight-bold"
                    >Team and Security > click Team.</b
                  >
                </li>
                <li class="mb-2">
                  On the Team page,
                  <b class="display-1 font-weight-bold">click + New Member.</b>
                </li>
                <li class="mb-2">
                  Add
                  <a
                    class="display-1 font-weight-bold"
                    href="mailto:phil@wearelocals.co.uk"
                    >phil@wearelocals.co.uk</a
                  >
                  and choose “Developer”.
                </li>
                <li class="mb-2">
                  Within a few hours, your Stripe account will be updated with a
                  primary active status.
                </li>
              </ol>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="stripe_dialog = false">
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row
      class="justify-space-between"
      v-if="merchant_id_ready && password_is_ready"
    >
      <v-col cols="12" class="pb-0">
        <h1 class="text--secondary">Unify integration status</h1>
        <p class="mb-0">See status of your integration with Unify</p>
      </v-col>
      <v-col cols="12" md="6">
        <p>
          <v-icon v-if="merchant_id_ready" class="mr-4" color="primary" medium
            >mdi-check</v-icon
          ><v-icon v-else class="mr-4" color="red" medium>mdi-close</v-icon
          ><b>Merchant ID</b>
        </p>
        <p>
          <v-icon
            v-if="password_is_ready"
            class="mr-4 primary--text"
            color="primary"
            medium
            >mdi-check</v-icon
          ><v-icon v-else class="mr-4" color="red" medium>mdi-close</v-icon
          ><b>Secret key</b>
        </p>
        <v-btn
          rounded
          color="primary"
          href="https://mms.unyfigateway.co.uk"
          target="_blank"
        >
          <v-icon left>mdi-open-in-new</v-icon> to Merchant Management System
        </v-btn>
      </v-col>
      <v-col cols="12" class="pb-0">
        <h1 class="text--secondary">
          Disable Address Verification Service (AVS) Setting
        </h1>
        <p class="mb-0">
          During checkout, our default customer payment settings include a
          postcode field and AVS check. You have the choice to disable this
          here.
        </p>
      </v-col>
      <v-col cols="12" md="6">
        <div>
          <v-checkbox
            color="primary"
            v-model="value.unify.avs_accept_risk"
            label="I confirm I want to do this and fully understand the risks involved"
            hide-details="auto"
            class="d-inline-block"
          />
        </div>
      </v-col>
      <template v-if="value.unify.avs_accept_risk">
        <v-col cols="12" class="pb-0">
          <h1 class="text--secondary">Disable AVS Check</h1>
          <p class="mb-0">
            To disable AVS check, log in to your Unyfi MMS
            <a href="https://mms.unyfigateway.co.uk" target="_blank">here</a>
            and follow instructions below
          </p>
        </v-col>
        <v-col cols="12">
          <ul style="list-style: decimal">
            <li>
              On the left hand side of your MMS dashboard, click
              <b>Account Settings</b>.
            </li>
            <li>Scroll down to <b> Default AVS Behaviour</b> section</li>
            <li>Find option <b>AVS Policy</b></li>
            <li>Select option <b>“Do Not Fail”</b></li>
            <li>
              Click <b>Submit Account Settings</b> on the bottom of the page
            </li>
            <li>Return to this admin dashboard and <b>confirm changes</b>.</li>
          </ul>
        </v-col>
        <v-col cols="12">
          <div>
            <v-checkbox
              color="primary"
              v-model="value.unify.confirm_avs_policy"
              label="I confirm I have disabled AVS check in Unyfi"
              hide-details="auto"
              class="d-inline-block"
            />
          </div>
          <div>
            <v-checkbox
              v-if="value.unify.confirm_avs_policy"
              color="primary"
              v-model="value.unify.disable_avs_check"
              label="Remove checkout postcode field"
              hide-details="auto"
              class="d-inline-block"
            />
          </div>
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import { db } from "@/db";
import { mapState } from "vuex";
import { VModelObjectMixin } from "@/components/mixins/InputMixins";

const UNIFY_REGISTRATION_URL =
  "https://mms.unyfigateway.co.uk/Pages/PublicPages/RegisterMerchant.aspx?ResellerID=F586DB16-335E-446F-BBB5-8686F578A88A";

export default {
  name: "UnifyPaymentsSettings",
  mixins: [VModelObjectMixin],
  data() {
    return {
      UNIFY_REGISTRATION_URL,
      merchant_id_ready: null,
      password_is_ready: null,
      stripe_dialog: false,
      unify_dialog: false,
      avs_accept_risk: false,
    };
  },
  computed: {
    ...mapState("AdminStore", ["shop_id"]),
  },
  async created() {
    const credentials = await db
      .collection("shops")
      .doc(this.shop_id)
      .collection("admin_data")
      .doc("unify-credentials")
      .get()
      .then((doc) => (doc.exists ? doc.data() : null));

    this.merchant_id_ready = credentials?.merchant_id !== null ?? false;
    this.password_is_ready = credentials?.password !== null ?? false;
  },
};
</script>
