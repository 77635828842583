<template>
  <v-select
    v-model="value.sound_notification_file_name"
    :items="sounds"
    label="New Order Notification sound"
    prepend-icon="mdi-volume-high"
  ></v-select>
</template>

<script>
import { VModelObjectMixin } from "@/components/mixins/InputMixins";

export default {
  name: "HospitalitySoundNotificationSelect",
  mixins: [VModelObjectMixin],
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {
    sounds() {
      return [
        { text: "Default", value: "tone-0" },
        { text: "Tone 1", value: "tone-1" },
        { text: "Tone 2", value: "tone-2" },
        { text: "Tone 3", value: "tone-3" },
        { text: "Tone 4", value: "tone-4" },
        { text: "Tone 5", value: "tone-5" },
        { text: "Tone 6", value: "tone-6" },
        { text: "Tone 7", value: "tone-7" },
        { text: "Tone 8", value: "tone-8" },
        { text: "Tone 9", value: "tone-9" },
      ];
    },
    selected_tone() {
      return this.value.sound_notification_file_name;
    },
  },
  watch: {
    selected_tone: {
      handler(tone) {
        if (tone) {
          const sound_file = require(`@/assets/sounds/${tone}.mp3`);
          const sound = new Audio(sound_file);
          sound.play();
        }
      },
    },
  },
};
</script>
