<template>
  <div class="subscription">
    <div v-for="(tier, idx) in tiers" :key="`tier_${idx}`" class="wrapper">
      <div class="tier mb-3" :class="{ 'best-offer': tier.best_offer }">
        <div
          class="header py-4 d-flex justify-center flex-column"
          :class="tier.color"
        >
          <p class="--name mb-0">{{ tier.name }}</p>
          <p class="--subtitle mb-0">{{ tier.subtitle }}</p>
        </div>
        <ul>
          <li
            v-for="(feature, jdx) in tier.features"
            :key="`feature_${idx}_${jdx}`"
          >
            {{ feature }}
          </li>
        </ul>
      </div>
      <v-btn
        @click="tier.action(idx)"
        :disabled="loading_idx === idx"
        block
        rounded
        color="primary"
      >
        <v-progress-circular
          v-if="loading_idx === idx"
          color="white"
          :size="25"
          indeterminate
        />
        <span v-else>{{ tier.action_text }}</span></v-btn
      >
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { upsert_service } from "@/requests";

export default {
  name: "TableBookingSubscription",
  data() {
    return {
      loading_idx: null,
    };
  },
  computed: {
    ...mapGetters("TableBookingStore", ["is_table_booking_enabled"]),
    ...mapState("AdminStore", ["shop_id", "shop"]),
    ...mapGetters("ShopStore", ["table_booking"]),
    tiers() {
      return [
        {
          name: "Free",
          subtitle: "1-month trial",
          color: "primary",
          features: [
            "Booking Management",
            "Table Management",
            "Booking deposits",
            "Booking cancellation",
            "Walk-ins",
            "Embeddable widget for your website",
          ],
          action_text: this.is_table_booking_enabled
            ? "Disable"
            : "Try out for FREE",
          action: (idx) => {
            this.loading_idx = idx;
            this.update_table_booking_service();
          },
        },
        {
          name: "£49.99",
          subtitle: "per month",
          color: "accent",
          features: [
            "Marketing stuff",
            "Booking with Menu options",
            "Capacity management",
            "Reviews",
            "Swag",
            "Cool water bottle",
            "Stickers",
          ],
          action_text: "Sign up now",
          action: () => console.log("@click basic"),
        },
        {
          name: "£499.99",
          subtitle: "per year",
          color: "primary",
          best_offer: true,
          features: [
            "Everything from free and basic plan",
            "Advanced booking configuration",
            "Christian promises",
            "Cherine support 24/7",
            "Phil's promises",
            "Payji Support",
            "Krystian neumporphism",
            "Being cool",
          ],
          action_text: "Sign up now",
          action: () => console.log("@click premium"),
        },
      ];
    },
  },
  methods: {
    async update_table_booking_service() {
      try {
        let table_booking;
        if (!this.table_booking) {
          table_booking = {
            is_enabled: true,
            is_active: false,
            shop_id: this.shop_id,
            service_type: "table_booking",
          };
        } else {
          table_booking = { ...this.table_booking };
          table_booking.is_enabled = !table_booking.is_enabled;
          table_booking.is_active = false;
        }
        await upsert_service(table_booking);
      } catch (error) {
        console.error("TableBookingSubscription/toggle_table_booking ", error);
      } finally {
        this.loading_idx = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$offset: 20px;

.subscription {
  margin-top: $offset;
  width: 100%;
  display: grid;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 4rem;
    padding: 0 1rem;
  }

  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    column-gap: 6rem;
    padding: 0 6rem;
  }
  .wrapper {
    margin-bottom: 6rem;
    @media #{map-get($display-breakpoints, 'md-and-up')} {
      margin-bottom: 0;
    }
  }
  .tier {
    background-color: #f0f0f0;
    position: relative;
    border-radius: 5px;
    padding-bottom: 1.5rem;
    text-align: center;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      min-height: 300px;
    }

    &.best-offer {
      .header {
        &:after {
          color: black;
          font-size: 12px;
          padding: 3.4px 10.3px 3.4px;
          border-radius: 30px;
          box-shadow: 1px 1px 8px 0 rgba(0, 0, 0, 0.16);
          content: "Best Offer";
          background-color: var(--v-yellow-base);
          position: absolute;
          top: -12px;
          left: 50%;
          transform: translate(-50%, 0);
          display: inline;
        }
      }
    }

    .header {
      color: white;
      margin: 0 auto;
      position: relative;
      top: -$offset;
      border-radius: 5px;
      width: 80%;
    }

    .--name {
      color: white;
      font-size: 25px;
      font-weight: bold;
    }
  }

  ul {
    padding: 0 8px;
    list-style-type: none;
  }
}
</style>
