<template>
  <v-container fluid>
    <v-form :value="value" @input="(value) => $emit('input', value)">
      <slot></slot>
    </v-form>
    <SettingsActions
      :label="actions_label"
      :required_field_msg="required_field_msg"
      :disabled="disable_actions"
      @update-shop="$emit('update-shop')"
    />
  </v-container>
</template>

<script>
import SettingsActions from "@/views/dashboard/components/shop_settings/SettingsActions";

export default {
  name: "SettingsActionTab",
  components: {
    SettingsActions,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    actions_label: {
      type: String,
    },
    required_field_msg: {
      type: Boolean,
      default: false,
    },
    disable_actions: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
